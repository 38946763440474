const URI_API = process.env.VUE_APP_API_URL;
export async function addToFavorites(slug, token) {
    const url =  `${URI_API}/add-favorite`;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({"yacht_slug": slug})
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();

    } catch (error) {
        console.error(error);
    }
}

export async function removeFromFavorites(slug, token) {
    const url =  `${URI_API}/remove-favorite`;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({"yacht_slug": slug})
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();

    } catch (error) {
        console.error(error);
    }
}

export async function restorePassword(email) {
    const url =  `${URI_API}/forgot-password`;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer 8MU4e2Z0biho5tYhZ5hKNGtLfMArSOZRW0ObrqXSb395d6cd'
        },
        body: JSON.stringify({"email":email})
    };
    try {
        const response = await fetch(url, options);
        const data = await response.json();
        if(data.success){
            this.$notify({
                title: "Success!",
                text: "You successfully asked for password restoring. Please check your mail",
            });
            this.closeModal();
        } else {
            this.$notify({
                type: 'warn',
                title: 'Warning',
                text: "This email wasn\'t registered before"
            });
        }


    } catch (error) {
        console.error(error);
    }
}