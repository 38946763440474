import axios from 'axios';

const URI_API = process.env.VUE_APP_API_URL;

const state = {
    yachts: [],
    currentPage: 1,
    totalPages: 1,
    total: null,
    filtered: null,
    nextPageUrl: null,
    prevPageUrl: null,
    limits: {},
    shipYards: {},
    mainSailTypes: {},

};

const mutations = {
    SET_YACHTS(state, yachts) {
        state.yachts = yachts;
    },
    SET_PAGINATION(state, pagination) {
        state.currentPage = pagination.currentPage;
        state.totalPages = pagination.totalPages;
        state.nextPageUrl = pagination.nextPageUrl;
        state.prevPageUrl = pagination.prevPageUrl;
        state.total = pagination.total;
        state.filtered = pagination.filtered;
        state.links = pagination.links;
    },
    SET_LIMITS(state, limits) {
        state.limits = limits;
    },
    SET_SHIPYARDS(state, shipYards) {
        state.shipYards = shipYards;
    },
    SET_MAINSAILTYPES(state, mainSailTypes){
        state.mainSailTypes = mainSailTypes;
    }
};

const actions = {
    async fetchYachts({ commit }, page = 1) {
        try {
            let url = `${URI_API}/yacht-list?page=${page}`;
            const queryParams = new URLSearchParams(window.location.search);

            if (!queryParams.get('destinations')) {
                return;
            }

            const params = {};
            const directKeys = [
                "product",
                "order_by",
                "destinations",
                "dateRange",
                "date_start",
                "date_end",
                "type",
                "page",
                "manufacturer",
                "main_sail_types",
                "product"
            ];

            let shipyardIds = [];
            let modelIds = [];

            // Extract shipyard_ids and model_ids from 'manufacturer' parameter
            const manufacturerParam = queryParams.get('manufacturer');
            if (manufacturerParam) {
                manufacturerParam.split(',').forEach(entry => {
                    const match = entry.match(/^(\d+)(?:\[(.+)\])?$/);
                    if (match) {
                        const shipyardId = match[1]; // Get shipyard ID
                        const models = match[2]; // Get models if any

                        if (models) {
                            // If models exist, add them to modelIds array
                            modelIds.push(...models.split(';'));
                        } else {
                            // No models for this shipyard, add to shipyardIds
                            shipyardIds.push(shipyardId);
                        }
                    }
                });
            }

            // Update queryParams for shipyard_ids and model_ids
            if (shipyardIds.length > 0) {
                params['shipyard_ids'] = shipyardIds.join(',');
            }

            // Ensure modelIds is added to params if there are models selected
            if (modelIds.length > 0) {
                params['model_ids'] = modelIds.join(',');
            }

            // Add other direct parameters to params object
            for (const [key, value] of queryParams.entries()) {
                if (directKeys.includes(key) && key !== 'manufacturer') {
                    params[key] = value;
                } else if (!directKeys.includes(key)) {
                    const [min, max] = value.split("-").map(Number);
                    params[`${key}_min`] = min;
                    params[`${key}_max`] = max;
                }
            }

            // Construct the URL with all parameters
            for (const [key, value] of Object.entries(params)) {
                url += `&${key}=${value}`;
            }

            const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${tokenBearer}`
                }
            });

            const data = response.data;
            console.log(data);
            commit('SET_YACHTS', data.data);
            commit('SET_PAGINATION', {
                currentPage: data.current_page,
                lastPage: data.last_page,
                nextPageUrl: data.next_page_url,
                prevPageUrl: data.prev_page_url,
                total: data.total,
                links: data.links
            });
            console.log(data.limits)
            commit('SET_LIMITS', data.limits);
            commit('SET_SHIPYARDS', data.shipyards);
            commit('SET_MAINSAILTYPES', data.main_sail_types);
        } catch (error) {
            console.error('Error fetching yachts:', error);
        }
    }


};

const getters = {
    getYachts: state => state.yachts,
    getCurrentPage: state => state.currentPage,
    getLastPage: state => state.lastPage,
    getNextPageUrl: state => state.nextPageUrl,
    getPrevPageUrl: state => state.prevPageUrl,
    getTotal: state => state.total,
    getLinks: state => state.links,
    getLimits: state => state.limits,
    getShipYards: state => state.shipYards,
    getMainSailTypes: state => state.mainSailTypes,
};

export default {
    state,
    mutations,
    actions,
    getters
};
