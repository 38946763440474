<script>
import {BASE} from "@/mixins";
import NoUiSliderCustom from "@/components/partials/NoUiSliderCustom.vue";
import Select2 from "vue3-select2-component";
import SideSearchForm from "@/components/partials/SideSearchForm.vue";
import {mapActions, mapGetters} from "vuex";
import clickOutSide from "@mahdikhashan/vue3-click-outside";

export default {
  name: "FullSearch",
  components: {SideSearchForm, NoUiSliderCustom, Select2},
  mixins: [BASE],
  directives: {
    clickOutSide,
  },
  props: {
    showFilter: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      manufacturerSearch: '',
      modelSearch: '',
      bareboat: false,
      crewed: false,
      showManufacturers: false,
      showModels: false,
      selectedShipYards: [],
      selectedModels: [],
      selectedProducts: [],
      selectedMainSailTypes: []
    }
  },
  computed: {
    ...mapGetters(["getShipYards", "getMainSailTypes", "getLimits"]),
    filteredManufacturers() {
      const shipYardsArray = Object.values(this.getShipYards);  // Convert object to array
      if (!this.manufacturerSearch) {
        return this.getShipYards;  // Return full list if no search input
      }
      return shipYardsArray.filter(manufacturer =>
          manufacturer.name && manufacturer.name.toLowerCase().includes(this.manufacturerSearch.toLowerCase())
      );
    },
    selectedManufacturersList() {
      const shipYardsArray = Object.values(this.getShipYards);  // Convert object to array
      // Returns an array of shipyards filtered by selected shipyards
      return shipYardsArray.filter(shipyard => {
        // Check if the shipyard is in the selectedShipYards array
        return this.selectedShipYards.includes(shipyard.shipyard_id);
      });
    },
    selectedModelsList() {
      // Initialize an empty array to hold the selected models
      const models = [];
      const shipYardsArray = Object.values(this.getShipYards);  // Convert object to array
      // Iterate through each shipyard in getShipYards
      shipYardsArray.forEach(shipyard => {
        // Iterate through each model in the shipyard's models object
        for (const modelKey in shipyard.models) {
          const model = shipyard.models[modelKey];
          // Check if the model is in the selectedModels array
          if (this.selectedModels.includes(model.model_id) && this.selectedShipYards.includes(shipyard.shipyard_id)) {
            // If so, add the model to the models array
            models.push(model);
          }
        }
      });

      return models; // Return the array of selected models
    }
  },
  watch: {
    bareboat(newVal) {
      this.updateUrlParams();
    },
    crewed(newVal) {
      this.updateUrlParams();
    }
  },
  methods: {
    ...mapActions(['fetchYachts']),
    filteredModels(shipyard) {
      const modelsArray = Object.values(shipyard.models);

      if (!this.modelSearch) {
        return modelsArray;
      }

      return modelsArray.filter(model =>
          model.name.toLowerCase().includes(this.modelSearch.toLowerCase())
      );
    },
    updateUrlParams() {
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);

      // If both checkboxes are selected or neither is selected, remove the 'product' parameter
      if ((this.bareboat && this.crewed) || (!this.bareboat && !this.crewed)) {
        params.delete('product');
      } else {
        // Otherwise, set the 'product' parameter to the selected option
        if (this.bareboat) {
          params.set('product', 'bareboat');
        } else if (this.crewed) {
          params.set('product', 'crewed');
        }
      }

      history.replaceState(null, '', url.pathname + '?' + params.toString());
      this.fetchYachts();
    },
    filterByManufacturer(manufacturer, fromList = true) {
      const shipyard_id = manufacturer.shipyard_id;

      // Toggle the shipyard in the selectedShipYards array
      const indexId = this.selectedShipYards.indexOf(shipyard_id);
      if (indexId !== -1) {
        this.selectedShipYards.splice(indexId, 1);
      } else {
        this.selectedShipYards.push(shipyard_id);
      }

      // Prepare to update the URL parameters
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);

      // Update the 'manufacturer' parameter in the URL
      let manufacturerParam = params.get('manufacturer');
      let manufacturerMap = {};

      // Parse existing manufacturer parameter to object
      if (manufacturerParam) {
        manufacturerParam.split(',').forEach(entry => {
          const [shipyard, models] = entry.split('[');
          manufacturerMap[shipyard] = models ? models.replace(']', '').split(',') : [];
        });
      }

      // Toggle the selected shipyard and clear its models if deselected
      if (manufacturerMap[shipyard_id]) {
        delete manufacturerMap[shipyard_id];
      } else {
        manufacturerMap[shipyard_id] = [];
      }

      // Convert the manufacturer map back to a string for the URL
      const newManufacturerParam = Object.entries(manufacturerMap)
          .map(([shipyard, models]) => models.length ? `${shipyard}[${models.join(',')}]` : shipyard)
          .join(',');

      if (newManufacturerParam) {
        params.set('manufacturer', newManufacturerParam);
      } else {
        params.delete('manufacturer');
      }
      if(this.selectedShipYards.length === 0){
        this.selectedModels.length = 0;
        this.selectedModelsList.length = 0;
        params.delete('manufacturer');
      }
      // Update the URL without reloading the page
      history.replaceState(null, '', url.pathname + '?' + params.toString());

      // Fetch updated yacht data and close modals
      this.fetchYachts();
      if(fromList){
        this.closeModals();
      }
    },

    filterByModel(model, shipyardId=null, fromList=true) {
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);

      // Get or initialize the 'manufacturer' parameter
      let manufacturerParam = params.get('manufacturer');
      let manufacturerMap = {};

      const indexId = this.selectedModels.indexOf(model.model_id);
      if (indexId !== -1) {
        this.selectedModels.splice(indexId, 1);
      } else {
        this.selectedModels.push(model.model_id);
      }

      // Parse existing manufacturer parameter to object
      if (manufacturerParam) {
        manufacturerParam.split(',').forEach(entry => {
          const [shipyard, models] = entry.split('[');
          manufacturerMap[shipyard] = models ? models.replace(']', '').split(';') : [];
        });
      }

      // Ensure the shipyard is present in the manufacturer map
      if (!manufacturerMap[shipyardId]) {
        manufacturerMap[shipyardId] = [];
      }

      // Toggle the model in the list of selected models for the shipyard
      const modelIndex = manufacturerMap[shipyardId].indexOf(String(model.model_id));
      if (modelIndex !== -1) {
        manufacturerMap[shipyardId].splice(modelIndex, 1);
      } else {
        manufacturerMap[shipyardId].push(String(model.model_id));
      }

      // Remove the shipyard if no models are selected
      if (manufacturerMap[shipyardId].length === 0) {
        delete manufacturerMap[shipyardId];
      }

      // Convert the manufacturer map back to a string for the URL
      const newManufacturerParam = Object.entries(manufacturerMap)
          .map(([shipyard, models]) => models.length ? `${shipyard}[${models.join(';')}]` : shipyard)
          .join(',');

      if (newManufacturerParam) {
        params.set('manufacturer', newManufacturerParam);
      } else {
        params.delete('manufacturer');
      }

      // Update the URL without reloading the page
      history.replaceState(null, '', url.pathname + '?' + params.toString());

      // Fetch updated yacht data and close modals
      this.fetchYachts();
      if(fromList){
        this.closeModals();
      }
    },

    filterByMainSailType(type, count) {
      if(!count)return;
      const urlParams = new URLSearchParams(window.location.search);
      let mainSailTypes = urlParams.get('main_sail_types');
      let mainSailTypesArray = mainSailTypes ? mainSailTypes.split(',') : [];
      const typeIndex = mainSailTypesArray.indexOf(type);

      // Add or remove the type from the URL parameter and selectedMainSailTypes array
      if (typeIndex !== -1) {
        mainSailTypesArray.splice(typeIndex, 1);
        this.selectedMainSailTypes.splice(this.selectedMainSailTypes.indexOf(type), 1);
      } else {
        mainSailTypesArray.push(type);
        this.selectedMainSailTypes.push(type);
      }

      // Update the URL parameter
      if (mainSailTypesArray.length > 0) {
        urlParams.set('main_sail_types', mainSailTypesArray.join(','));
      } else {
        urlParams.delete('main_sail_types');
      }

      // Update the URL without reloading the page
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
      this.fetchYachts();
      this.closeModals()
    },
    filterByProduct(product){
      const urlParams = new URLSearchParams(window.location.search);
      let products = urlParams.get('product');
      let productsArray = products ? products.split(',') : [];

      const productIndex = productsArray.indexOf(product);
      const selectedProductIndex = this.selectedProducts.indexOf(product);

      // Handle product selection/deselection
      if (selectedProductIndex !== -1) {
        // If the product is already selected, remove it from both arrays
        this.selectedProducts.splice(selectedProductIndex, 1);
        productsArray.splice(productIndex, 1);
      } else {
        // If another product is already selected and you add a new one, remove URL param but keep in selectedProducts
        if (productsArray.length === 1) {
          productsArray = [];
          urlParams.delete('product');
        }
        // Add the new product to the selected products list if it's not already there
        this.selectedProducts.push(product);
      }

      // Update the URL parameter only if there's a single product selected
      if (this.selectedProducts.length === 1) {
        urlParams.set('product', this.selectedProducts[0]);
      } else {
        urlParams.delete('product');
      }

      // Update the URL without reloading the page
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      // Fetch updated yacht data
      this.fetchYachts();
    },
    openManufacturersList() {
      this.showManufacturers = true;
      this.showModels = false;
    },
    openModelsList() {
      if(this.selectedShipYards.length === 0 ) {
        this.openManufacturersList();
      } else {
        this.showModels = true;
        this.showManufacturers = false;
      }
    },
    closeModals() {
      this.showModels = false;
      this.showManufacturers = false;
    },
    updateSelectedParametersFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);

      const manufacturerParam = urlParams.get('manufacturer');
      const sailTypes = urlParams.get('main_sail_types');
      const product = urlParams.get('product');

      if (manufacturerParam) {
        // Split the 'manufacturer' parameter by commas to separate each entry
        manufacturerParam.split(',').forEach(entry => {
          // Match the pattern for extracting shipyard and models
          const match = entry.match(/^(\d+)(?:\[(.+)\])?$/);
          if (match) {
            const shipyardId = Number(match[1]); // Extract shipyard ID as a number
            const models = match[2] ? match[2].split(';').map(Number) : []; // Extract model IDs as numbers

            // Add the shipyard ID to the selectedShipYards array
            this.selectedShipYards.push(shipyardId);

            // If models exist for this shipyard, add them to the selectedModels array
            if (models.length > 0) {
              this.selectedModels.push(...models);
            }
          }
        });
      }

      // Check if 'main_sail_types' parameter is present and decode it
      if (sailTypes) {
        this.selectedMainSailTypes = sailTypes.split(',').map(type => decodeURIComponent(type));
      }

      // Check if 'product' parameter is present and decode it
      if (product) {
        this.selectedProducts = product.split(',').map(type => decodeURIComponent(type));
      }

    },
    updateFilters(){
      this.$emit('updateFilters');
    }
  },
  mounted() {
    this.updateSelectedParametersFromUrl();
  }
}
</script>

<template>
  <SideSearchForm/>
  <div class="filter" >
    <NoUiSliderCustom :min="+getLimits.cabins_min || 0.1" :max="+getLimits.cabins_max" :title="$t('yacht.cabins')"
                      param="cabins"
                      v-if="getLimits.cabins_max" @updateFilters="updateFilters"/>
    <NoUiSliderCustom :min="+getLimits.price_min || 0.1" :max="+getLimits.price_max" :title="$t('yacht.price')" param="price"
                      v-if="getLimits.price_max" @updateFilters="updateFilters" />
    <NoUiSliderCustom :min="+getLimits.people_min || 0.1" :max="+getLimits.people_max" :title="$t('yacht.people')"
                      param="people"
                      v-if="getLimits.people_max" @updateFilters="updateFilters" />
    <NoUiSliderCustom :min="+getLimits.berths_min || 0.1" :max="+getLimits.berths_max" :title="$t('yacht.berths')"
                      param="berths"
                      v-if="getLimits.berths_max" @updateFilters="updateFilters" />
    <NoUiSliderCustom :min="+getLimits.length_min || 0.1" :max="+getLimits.length_max" :title="$t('yacht.length')"
                      param="length"
                      v-if="getLimits.length_max" @updateFilters="updateFilters" />
    <NoUiSliderCustom :min="+getLimits.year_min || 0.1" :max="+getLimits.year_max" :title="$t('yacht.year')" param="year"
                      v-if="getLimits.year_max" @updateFilters="updateFilters" />
    <NoUiSliderCustom :min="+getLimits.wc_min" :max="+getLimits.wc_max" :title="$t('yacht.toilets')" param="wc"
                      v-if="getLimits.wc_max" @updateFilters="updateFilters" />
  </div>
  <div class="settings" >
    <div class="settings__item">
      <div class="settings__title">{{ $t('yacht.rental_type')}}</div>
      <label class="checkbox" @click.prevent="filterByProduct('bareboat')">
        <input type="checkbox"  :checked="selectedProducts.includes('bareboat')">
        <span class="checkbox__checkmark"></span>
        <span class="checkbox__text"><span>{{ $t('yacht.bareboat')}}</span><span></span></span>
      </label>
      <label class="checkbox" @click.prevent="filterByProduct('crewed')">
        <input type="checkbox" :checked="selectedProducts.includes('crewed')">
        <span class="checkbox__checkmark"></span>
        <span class="checkbox__text"><span>{{ $t('yacht.crewed')}}</span><span></span></span>
      </label>
    </div>
    <div class="settings__item">
      <div class="form-group" @click.stop="openManufacturersList">
        <label>{{ $t('yacht.manufacturer')}}</label>
        <input
            type="text"
            :placeholder="$t('actions.search')"
            v-model="manufacturerSearch"
        >
        <div
            class="settings__item-manufacturers-row-list"
            v-for="manufacturer in selectedManufacturersList"
            @click.prevent.stop="filterByManufacturer(manufacturer, false)"
        >
          <label class="checkbox">
            <input type="checkbox"
                   checked>
            <span class="checkbox__checkmark"></span>
            <span class="checkbox__text">
          <span>{{ manufacturer.name }}</span>
              <span class="checkbox__count">({{ manufacturer.count }})</span>
        </span>
          </label>
        </div>
        <div class="settings__item-manufacturers"
             v-if="showManufacturers"
             v-click-out-side.prevent="closeModals">

          <div class="settings__item-manufacturers-row"
               v-for="(manufacturer, id) in filteredManufacturers"
               :key="id"
               @click.prevent="filterByManufacturer(manufacturer)">
            <label class="checkbox">
              <input type="checkbox"
                     :checked="selectedShipYards.includes(manufacturer.shipyard_id)">
              <span class="checkbox__checkmark"></span>
              <span class="checkbox__text">
          <span>{{ manufacturer.name }}</span>
          <span class="checkbox__count">({{ manufacturer.count }})</span>
        </span>
            </label>
          </div>
        </div>
      </div>

      <div class="form-group" @click.stop="openModelsList">
        <label>Model</label>
        <input
            type="text"
            :placeholder="selectedShipYards.length === 0 ? 'Select manufacturer first' : 'Select model'"
            v-model="modelSearch"
        >
        <div
            class="settings__item-manufacturers-row-list"
            v-for="model in selectedModelsList"
            @click.prevent.stop="filterByModel(model, null, false)"
        >
          <label class="checkbox">
            <input type="checkbox"
                   checked>
            <span class="checkbox__checkmark"></span>
            <span class="checkbox__text">
          <span>{{ model.name }}</span><span class="checkbox__count">({{ model.count }})</span>
        </span>
          </label>
        </div>
        <div class="settings__item-models" v-if="showModels" v-click-out-side.stop="closeModals">
          <template v-for="shipyard in getShipYards" :key="shipyard.shipyard_id">
            <div  v-for="model in filteredModels(shipyard)" :key="model.model_id">
              <div class="settings__item-models-row" v-if="selectedShipYards.length === 0 || selectedShipYards.includes(shipyard.shipyard_id)">
                <label class="checkbox"
                       @click.prevent="filterByModel(model, shipyard.shipyard_id)">
                  <input type="checkbox" :checked="selectedModels.includes(model.model_id)">
                  <span class="checkbox__checkmark"></span>
                  <span class="checkbox__text"><span>{{ model.name }}</span><span class="checkbox__count">({{ model.count }})</span></span>
                </label>
              </div>
            </div>

          </template>
        </div>
      </div>
      <!--      <div class="form-group">-->
      <!--        <label>Amenities</label>-->
      <!--        <input type="text" :placeholder="$t('actions.search')">-->
      <!--      </div>-->
    </div>
    <!--    <div class="settings__item">-->
    <!--      <div class="settings__title">Review score</div>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>6+ </span><span>(73)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>7+ </span><span>(64)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>8+ </span><span>(60)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>9+ </span><span>(30)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>10 </span><span>(25)</span></span>-->
    <!--      </label>-->
    <!--    </div>-->
    <div class="settings__item">
      <div class="settings__title">{{ $t('yacht.type_of_mainsail')}}</div>
        <label class="checkbox" v-for="(count, type) in getMainSailTypes" :key="type">
          <input type="checkbox" @click="filterByMainSailType(type, count)" :checked="selectedMainSailTypes.includes(type)" :disabled="count===0">
          <span class="checkbox__checkmark"></span>
          <span class="checkbox__text">
          <span :class="{grey: count===0 }">{{ type }}</span>
          <span class="checkbox__count"  v-if="count">({{ count }})</span>
        </span>
      </label>
    </div>
    <!--    TODO: show when filter is ready-->
    <!--    <div class="settings__item">-->
    <!--      <div class="settings__title">Engine</div>-->
    <!--      <div class="range-item__slider" id="rangeSlider8"></div>-->
    <!--      <div class="range-item__values">-->
    <!--        <div id="rangeStart8"></div>-->
    <!--        <div id="rangeEnd8"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="settings__item">-->
    <!--      <div class="form-group">-->
    <!--        <label>Charter company</label>-->
    <!--        <input type="text" placeholder="Search">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="settings__item">-->
    <!--      <div class="settings__title">Services</div>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Deposit insurance </span><span>(176)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>Pet friendly </span><span>(25)</span></span>-->
    <!--      </label>-->
    <!--    </div>-->
  </div>
</template>

<style scoped>
.form-group {
  position: relative;
}

.settings__item-models,
.settings__item-manufacturers {
  position: absolute;
  padding-top: 15px;
  top: 80px;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 450px;
  overflow: scroll;
}

.settings__item-manufacturers-row {
  padding: 5px 10px;
  line-height: 30px;
  cursor: pointer;
}
.settings__item-manufacturers-row-list {
  padding: 0px 10px;
  cursor: pointer;
}
.settings__item-models-row {
  padding: 0px 10px;
  margin-bottom: 9px;
  line-height: 30px;
  cursor: pointer;
}

.settings__item-manufacturers-row span:last-child,
.settings__item-models-row span:last-child {
  margin-left: 5px;
}

.settings__item-manufacturers::-webkit-scrollbar,
.settings__item-models::-webkit-scrollbar {
  width: 6px;
}

.settings__item-manufacturers::-webkit-scrollbar-thumb,
.settings__item-models::-webkit-scrollbar-thumb {
  background-color: #BF8D37;
  border-radius: 10px;
}

.settings__item-manufacturers::-webkit-scrollbar-track,
.settings__item-models::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.checkbox__count {
  margin-left: 5px;
}
.grey {
  color: grey;
  font-weight: 200;
}
</style>
