<script>

import {calculateProductPrice} from "@/utilities/service";
import {API_URL} from "@/constants";

export default {
  name: "OfferCard",
  props: {
    yacht: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      flexibleCancellation: null,
      expandedSections: {
        otherInformation: false,
        services: false,
        excludedCharges: false,
        extras: false,
        extrasBase: false,
      },
      customer: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        specialRequests: "",
        selectedExtras: [],
      }
    }
  },
  computed: {
    obligatoryPayableInBase() {
      if (!this.yacht.yacht || !this.yacht.yacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.yacht.yacht.products;
      if (!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && prod.payable_in_base);
    },
    obligatoryPayableImmediately() {
      if (!this.yacht.yacht || !this.yacht.yacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.yacht.yacht.products;
      if (!products.length) return [];

      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && !prod.payable_in_base);
    },
    optionalPayableInBase() {
      if (!this.yacht.yacht || !this.yacht.yacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.yacht.yacht.products;
      if (!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && prod.payable_in_base);
    },
    optionalPayableImmediately() {
      if (!this.yacht.yacht || !this.yacht.yacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.yacht.yacht.products;
      if (!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && !prod.payable_in_base);
    },
    discount(){
      return (this.yacht.price-this.yacht.total_price).toFixed(2);
    }
  },
  methods: {
    calculateProductPrice,
    getFlagClass(query) {
      if (query) {
        return `fi icon-flag fi-${query.toLowerCase()}`;
      }
      return '';
    },
    toggleSection(section) {
      this.expandedSections[section] = !this.expandedSections[section];
    },
    isExpanded(section) {
      return this.expandedSections[section];
    },
    toggleExtraProduct(product) {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      // if (product === 'fc') {
      //   if (params.has('fc')) {
      //     params.delete('fc');
      //   } else {
      //     params.set('fc', 'true');
      //     this.flexibleCancellation = true;
      //   }
      //
      //   history.replaceState(null, '', url.toString());
      //   return;
      // }
      if (params.has(product.id.toString())) {
        params.delete(product.id.toString());
        product.checked = false;
        if (product.payable_in_base) {
          this.excludedExtrasPrice -= Number(this.calculateProductPrice(product));
        } else {
          this.includedExtrasPrice -= Number(this.calculateProductPrice(product));
        }
      } else {
        params.set(product.id.toString(), 'true');
        product.checked = true;
        if (product.payable_in_base) {
          this.excludedExtrasPrice += Number(this.calculateProductPrice(product));
        } else {
          this.includedExtrasPrice += Number(this.calculateProductPrice(product));
        }
      }

      history.replaceState(null, '', url.toString());
    },
    formatDateRange(startDate, endDate) {
      // Convert the input date strings to Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Helper function to format date as dd / mm / yyyy
      const format = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day} / ${month} / ${year}`;
      };

      // Format both dates
      const formattedStart = format(start);
      const formattedEnd = format(end);

      // Return the formatted date range as a span element
      return `<span>${formattedStart} - ${formattedEnd}</span>`;
    },
    async saveBooking() {

      const currentUrl = new URL(window.location.href);
      const uuid = currentUrl.pathname.split('/').reverse()[0];
      const params = new URLSearchParams(currentUrl.search);


      params.forEach((value, key) => {
        if (value === 'true' && !isNaN(key)) {
          this.customer.selectedExtras.push(key);
        }
      });
      const body = {
        yacht_id: this.yacht.yacht.id,
        date_from: this.yacht.start_date,
        product_id: 4519,
        date_to: this.yacht.end_date,
        first_name: 'Andrey',
        last_name: 'Samaev',
        email: 'test@test.com',
        phone: '2222222222222223',
        comment: 'from offer',
        price: this.yacht.total_price,
        currency: this.yacht.yacht.currency,
        extra_id: this.customer.selectedExtras,
        offer: uuid
      };
      const url = `${API_URL}/order/create`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        },
        body: JSON.stringify(body)
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const locale = this.$i18n.locale;
        const orderCode = data.data.code;
        let newUrl = `/${locale}/thank-you?order=${orderCode}`;
        window.location.href = window.location.href = newUrl;

      } catch (error) {
        console.error('Error:', error);
      }
    },
    updateExtrasWithChecked(extras) {
      const urlParams = new URLSearchParams(window.location.search);
      // if (urlParams.has('fc')) {
      //   this.flexibleCancellation = true;
      // }
      extras.forEach(extra => {
        extra.checked = urlParams.has(extra.id.toString());
        if (extra.checked) {
          if (extra.payable_in_base) {
            this.excludedExtrasPrice += Number(this.calculateProductPrice(extra));
          } else {
            this.includedExtrasPrice += Number(this.calculateProductPrice(extra));
          }
        }
      });
    },
  },
  mounted() {
    this.updateExtrasWithChecked(this.yacht.yacht.products[0].extras);
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);


    params.forEach((value, key) => {
      if (value === 'true' && !isNaN(key)) {
        this.customer.selectedExtras.push(key);
      }
    });
  }
}
</script>

<template>
  <div class="mail-offer__wrapper">
    <div class="mail-offer__descr">
      <div class="mail-offer__descr-item">
        <a :href="`/${$i18n.locale}/${yacht.yacht.type.slug}/${yacht.yacht.slug}`" class="product" target="_blank">
          <div class="product__left">
            <div class="product__photo">
              <img v-if="yacht.yacht.mainImageUrls"
                   :src="yacht.yacht.mainImageUrls[400] || yacht.yacht.mainImageUrls[800]"
                   :alt="yacht.model + ' | ' + yacht.name" :title="yacht.yacht.name">
              <img v-else src="/images/default-small.png" :alt="yacht.model + ' | ' + yacht.name">
            </div>
          </div>
          <div class="product__right">
            <div class="product__title">
              <div class="product__flag">
                <span :class="getFlagClass(yacht.yacht.country.short)"></span>
              </div>
              <div class="prodcut__name">{{ yacht.yacht.model.name }} <span v-if="yacht.yacht.name">|</span>
                {{ yacht.yacht.name }}
              </div>
            </div>
            <div
                class="product__place"
                title="Click to see location"
            >{{ yacht.yacht.base.name }}, {{ yacht.yacht.base.city }}
            </div>
            <div class="product__info">
              <ul>
                <li><span>{{ $t('yacht.year') }}</span><span>{{ yacht.yacht.year }}</span></li>
                <li><span>{{ $t('yacht.people') }}</span><span>{{ yacht.yacht.max_people }}</span></li>
                <li><span>{{ $t('yacht.cabins') }}</span><span>{{ yacht.yacht.cabins }}</span></li>
              </ul>
              <ul>
                <li><span>{{ $t('yacht.yacht_type') }}</span><span>{{ yacht.yacht.type.name }}</span></li>
                <li><span>{{ $t('yacht.length') }}</span><span>{{ yacht.yacht.length }} m</span></li>
                <li><span>{{ $t('yacht.mainsail') }}</span><span>{{ yacht.yacht.main_sail_type }}</span></li>
              </ul>
            </div>
          </div>
        </a>
        <div class="card__lists technical-list">
          <ul>
            <li>
              <div class="card__list-title"><img src="/images/date.svg" alt="Year built">{{ $t('yacht.year') }}</div>
              <span></span>
              <div class="card__list-value">{{ yacht.yacht.year }}</div>
            </li>
            <li>
              <div class="card__list-title"><img src="/images/people.svg" alt="Max passengers">{{ $t('yacht.people') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ yacht.yacht.max_people }}</div>
            </li>
            <li>
              <div class="card__list-title"><img src="/images/cabins.svg" alt="Cabins amount">{{ $t('yacht.cabins') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ yacht.yacht.cabins }}</div>
            </li>
          </ul>
          <ul>
            <li>
              <div class="card__list-title">
                <img src="/images/location.svg" alt="Location place">{{ $t('yacht.location') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ yacht.yacht.country.name }}</div>
            </li>
            <li>
              <div class="card__list-title">
                <img src="/images/berths.svg" alt="Berths amount">{{ $t('yacht.berths') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ yacht.yacht.berths }}</div>
            </li>
            <li>
              <div class="card__list-title"><img src="/images/toilets.svg" alt="WC's amount">{{ $t('yacht.toilets') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ yacht.yacht.wc }}</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="mail-offer__descr-item">
        <!-- Other Information Section -->
        <div class="card__heading" @click="toggleSection('otherInformation')">
          <h2 class="card__heading-name">{{ $t('yacht.other_information') }}</h2>
          <div class="card__heading-arrow">
            <img :src="isExpanded('otherInformation') ? '/images/dropdown-up.svg' : '/images/dropdown.svg'"
                 alt="Toggle section">
          </div>
        </div>
        <transition name="collapse">
          <div v-show="isExpanded('otherInformation')" class="card__lists other-list">
            <ul>
              <li v-if="yacht.yacht.type">
                <div class="card__list-title">{{ $t('yacht.type_of_boat') }}:</div>
                <span></span>
                <div class="card__list-value">{{ yacht.yacht.type && yacht.yacht.type.name }}</div>
              </li>
              <li v-if="yacht.yacht.shipyard">
                <div class="card__list-title">{{ $t('yacht.brand') }}:</div>
                <span></span>
                <div class="card__list-value">{{ yacht.yacht.shipyard }}</div>
              </li>
              <li>
                <div class="card__list-title">{{ $t('yacht.beam') }} / {{ $t('yacht.draught') }}:</div>
                <span></span>
                <div class="card__list-value">{{ yacht.yacht.beam || 'NA' }} m / {{ yacht.yacht.draught || 'NA' }} m
                </div>
              </li>
              <li v-if="yacht.yacht.water_capacity">
                <div class="card__list-title">{{ $t('yacht.water_capacity') }}:</div>
                <span></span>
                <div class="card__list-value">{{ yacht.yacht.water_capacity }} l</div>
              </li>
            </ul>
            <ul>
              <li v-if="yacht.yacht.model">
                <div class="card__list-title">{{ $t('yacht.model') }}:</div>
                <span></span>
                <div class="card__list-value">{{ yacht.yacht.model && yacht.yacht.model.name }}</div>
              </li>
              <li v-if="yacht.yacht.length">
                <div class="card__list-title">{{ $t('yacht.length') }}:</div>
                <span></span>
                <div class="card__list-value">{{ parseFloat(yacht.yacht.length).toFixed(2) }} m</div>
              </li>
              <li v-if="yacht.yacht.fuel_capacity">
                <div class="card__list-title">{{ $t('yacht.fuel_capacity') }}:</div>
                <span></span>
                <div class="card__list-value">{{ yacht.yacht.fuel_capacity }} l</div>
              </li>
              <li>
                <div class="card__list-title">{{ $t('yacht.inside_showers') }}:</div>
                <span></span>
                <div class="card__list-value">{{ $t('interface.yes') }}</div>
              </li>
            </ul>
          </div>
        </transition>
      </div>

      <!-- Services Section -->
      <div class="mail-offer__descr-item">
        <div class="card__heading" @click="toggleSection('services')">
          <h2 class="card__heading-name">{{ $t('yacht.services') }} & {{ $t('yacht.extras') }}</h2>

        </div>
        <transition name="collapse">
          <div v-show="isExpanded('services')" v-if="obligatoryPayableImmediately.length">
            <div class="card__heading-subtitle">{{ $t('yacht.included_in_charge') }}</div>
            <label class="services__row" v-for="product in obligatoryPayableImmediately" :key="product.id">
              <div class="services__checkbox">
                <div class="checkbox">
                  <span class="checkbox__text">{{ product.name }}</span>
                </div>
                <div v-if="product.description" class="services__checkbox-descr">{{ product.description }}</div>
              </div>
              <div class="services__price">{{ product.price }} {{ product.currency }} {{ product.unit }}</div>
              <div class="services__optional">{{ $t('yacht.mandatory') }}</div>
            </label>
          </div>
        </transition>

        <!-- Excluded Charges Section -->
        <div class="card__heading" @click="toggleSection('excludedCharges')">
          <h2 class="card__heading-name card__heading-name-sub">{{ $t('yacht.excluded_charges') }}</h2>
          <div class="card__heading-arrow">
            <img :src="isExpanded('excludedCharges') ? '/images/dropdown-up.svg' : '/images/dropdown.svg'"
                 alt="Toggle section">
          </div>
        </div>
        <transition name="collapse">
          <div v-show="isExpanded('excludedCharges')" class="charges">
            <div class="card__heading-subtitle">{{ $t('yacht.to_be_paid_at_the_yacht_pick-up') }}</div>
            <div class="charges__row" v-for="product in obligatoryPayableInBase" :key="product.id">
              <div class="charges__name">
                <div class="charges__name-title">{{ product.name }}</div>
              </div>
              <div class="charges__price">{{ product.price }} {{ product.currency }} {{ product.unit }}</div>
              <div class="charges__mandatory">{{ $t('yacht.mandatory') }}</div>
            </div>
          </div>
        </transition>

        <!-- Extras Section -->
        <div class="card__heading" @click="toggleSection('extras')" v-if="optionalPayableImmediately.length">
          <h2 class="card__heading-name card__heading-name-sub">{{ $t('yacht.extras') }}</h2>
          <div class="card__heading-arrow">
            <img :src="isExpanded('extras') ? '/images/dropdown-up.svg' : '/images/dropdown.svg'" alt="Toggle section">
          </div>
        </div>
        <transition name="collapse">
          <div v-show="isExpanded('extras')" class="services" v-if="optionalPayableImmediately.length">
            <div class="card__heading-subtitle">{{ $t('yacht.book_now_or_later') }}</div>
<!--            TODO: uncomment flexible cancellation when periods and value will be known-->
<!--            <label class="services__row">-->
<!--              <div class="services__checkbox">-->
<!--                <div class="checkbox">-->
<!--                  <input type="checkbox" v-model="flexibleCancellation" @click="toggleExtraProduct('fc')"/>-->
<!--                  <span class="checkbox__checkmark"></span>-->
<!--                  <span class="checkbox__text">{{ $t('yacht.flexible_cancellation') }}</span>-->
<!--                </div>-->
<!--                <div class="services__checkbox-descr">-->
<!--                  {{ $t('yacht.flexible_cancellation_is_designed_for_cases_when_you_suddenly_need_to_cancel_your_sailing_holiday')-->
<!--                  }}-->
<!--                  {{ $t('yacht.it_also_covers_COVID-19_sickness,_hospitalization_or_forced_quarantine') }}-->
<!--                  {{ $t('interface.learn_more') }}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="services__price">8% {{ $t('yacht.from_rental_price') }}</div>-->
<!--              <div class="services__optional">{{ $t('yacht.optional') }}</div>-->
<!--            </label>-->
            <label class="services__row" v-for="product in optionalPayableImmediately" :key="product.id">
              <div class="services__checkbox">
                <div class="checkbox">
                  <input type="checkbox" @click="toggleExtraProduct(product)"/>
                  <span class="checkbox__checkmark"></span>
                  <span class="checkbox__text">{{ product.name }}</span>
                </div>
              </div>
              <div class="services__price">{{ product.price }} {{ product.currency }} {{ product.unit }}</div>
              <div class="services__optional">{{ $t('yacht.optional') }}</div>
            </label>
          </div>
        </transition>

        <div class="card__heading" @click="toggleSection('extrasBase')" v-if="optionalPayableInBase.length">
          <h2 class="card__heading-name card__heading-name-sub">{{ $t('yacht.extras') }}</h2>
          <div class="card__heading-arrow">
            <img :src="isExpanded('extrasBase') ? '/images/dropdown-up.svg' : '/images/dropdown.svg'"
                 alt="Toggle section">
          </div>
        </div>
        <transition name="collapse">
          <div v-show="isExpanded('extrasBase')" class="services" v-if="optionalPayableInBase.length">
            <div class="card__heading-subtitle">{{ $t('yacht.to_be_paid_at_the_yacht_pick-up') }}</div>
            <label class="services__row" v-for="product in optionalPayableInBase" :key="product.id">
              <div class="services__checkbox">
                <div class="checkbox">
                  <input type="checkbox" @click="toggleExtraProduct(product)" :checked="product.checked"/>
                  <span class="checkbox__checkmark"></span>
                  <span class="checkbox__text">{{ product.name }}</span>
                </div>
              </div>
              <div class="services__price">{{ product.price }} {{ product.currency }} {{ product.unit }}</div>
              <div class="services__optional">{{ $t('yacht.optional') }}</div>
            </label>
          </div>
        </transition>
      </div>
    </div>
    <div class="mail-offer__info">
      <div class="mail-offer__info-wrapper">
        <div class="mail-offer__info-price">{{ yacht.total_price }} €</div>
        <div class="mail-offer__info-price-total">
          <span>{{ $t('yacht.total_price') }}</span>
          <span>{{ yacht.price }} {{ yacht.yacht.currency }}</span>
        </div>
        <div class="mail-offer__info-discounts">
          <div class="mail-offer__info-discounts-title">{{ $t('yacht.discounts') }}:</div>
          <ul class="mail-offer__info-discounts-list">
            <li v-if="yacht.discount">
              <span>{{ $t('yacht.discount') }}<span class="mail-offer__info-discounts-value" v-if="yacht.discount.includes('%')"> {{ yacht.discount }}</span>:</span>
              <span v-if="yacht.discount.includes('%')">-{{ discount }} €</span>
              <span v-else>-{{ yacht.discount }} {{ yacht.yacht.currency }}</span>
            </li>
<!--            <li>-->
<!--              <span>{{ $t('yacht.low_season_special') }}:</span>-->
<!--              <span>-642,86 €</span>-->
<!--            </li>-->
<!--            <li>-->
<!--              <span>{{ $t('yacht.client_price') }}:</span>-->
<!--              <span>3.857.14 €</span>-->
<!--            </li>-->
            <li>
              <span>{{ $t('yacht.security_deposit') }}:</span>
              <span>{{ yacht.yacht.deposit.toFixed(2) }} {{ yacht.yacht.currency }}</span>
            </li>
          </ul>
          <a @click="saveBooking" class="btn">
            <span>{{ $t('actions.reserve') }}</span>
          </a>
        </div>
      </div>
      <div class="mail-offer__date">
        <img src="/images/calendar.svg" alt="Calendar pic">
        <span v-html="formatDateRange(yacht.start_date, yacht.end_date)"></span>
      </div>
    </div>
  </div>

</template>


<style scoped lang="scss">
.mail-offer__descr {
  width: calc(100% - 387px);

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.mail-offer__descr-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 19px;

  .card__heading {
    position: relative;
    cursor: pointer;

    .card__heading-arrow {
      position: absolute;
      right: 32px;
      transition: .2s ease-out;
      transform: rotate(180deg);

      @media(max-width: 767px) {
        right: 16px;
      }
    }

    &.active {
      .card__heading-arrow {
        transform: rotate(0);
      }
    }
  }
}

.mail-offer__info {
  width: 370px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media(max-width: 767px) {
    width: 100%;
  }
}

.mail-offer__info-wrapper {
  background: #ffffff;
  border-radius: 10px;
}

.mail-offer__info-price {
  font-weight: 400;
  font-size: 55px;
  line-height: 100%;
  color: #21084d;
  padding: 30px;

  @media(max-width: 1024px) {
    font-size: 28px;
    padding: 20px;
  }
}

.mail-offer__info-price-total {
  padding: 20px 30px;
  color: #ffffff;
  background: #bf8d37;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  line-height: 147%;

  span {
    &:last-child {
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
    }
  }
}

.mail-offer__info-discounts {
  padding: 20px 30px;

  @media(max-width: 767px) {
    padding: 20px;
  }

  .btn {
    width: 100%;
  }
}

.mail-offer__info-discounts-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 117%;
  color: #21084d;
  margin-bottom: 20px;
}

.mail-offer__info-discounts-list {
  background: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;

  li {
    display: flex;
    justify-content: space-between;

    span {
      color: #21084d;

      &:first-child {
        font-weight: 400;
        font-size: 15px;
        line-height: 147%;
      }

      &:last-child {
        font-weight: 600;
        font-size: 15px;
        line-height: 147%;
      }
    }
  }
}

.mail-offer__date {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #21084d;

  img {
    width: 60px;
    height: 60px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);;
  }

  span {
    padding-left: 20px;
  }
}

.icon-flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
}

.collapse-enter-active, .collapse-leave-active {
  transition: all 0.2s ease;
}

.collapse-enter, .collapse-leave-to {
  max-height: 1000px;
  opacity: 0;
  overflow: hidden;
}

.card__heading-subtitle {
  padding-left: 35px;
  line-height: 25px;
}

.card__heading {
  padding-bottom: 10px;
}
.card__heading-name-sub {
  font-size: 18px;
}
.mail-offer__info-discounts-value {
  margin-left: 5px;
}
</style>
