<template>
  <div class="container">
    <div class="page-nav">
      <BreadCrumbs :place="selectedDestinationsList"/>
    </div>
    <div class="catalog">
      <div class="sidebar">
        <div class="sidebar__close"><img src="/images/close.svg" alt="Close icon"></div>
        <FullSearch  :key="forceUpdateSearch" :showFilter="getYachts.length" @updateFilters="updateParams"/>
        <div class="manager">
          <div class="manager__title">{{ $t('interface.contact_us') }}</div>
          <div class="manager__text">{{ $t('interface.do_you_need_help') }} {{ $t('interface.we_speak_your_language_and_are_here_for_you_7_days_a_week') }}</div>
          <div class="manager__info">
            <div class="manager__descr"><span>{{ $t('interface.support') }}</span><span>{{ $t('interface.your_sailing_assistant') }}</span></div>
          </div>
          <div class="manager__contacts">
            <a :href="`mailto:${contacts.email}`">
              <img src="/images/mail.svg" alt="Email logo">
              <span>{{ contacts.email }}</span>
            </a>
            <a :href="`tel:${contacts.phone}`">
              <img src="/images/phone.svg" alt="Phone logo">
              <span>{{ contacts.phone }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="catalog__body">
        <div class="catalog__title" v-if="getYachts.length">{{ $t('yacht.yacht_charter_in') }} {{ selectedDestinationsList }}</div>
        <div class="catalog__available" v-if="getYachts.length">{{ getTotal }} {{ $t('yacht.boats') }} <span>{{ $t('yacht.available') }}</span></div>
        <div class="catalog__checked" v-if="getYachts.length">
          <div v-show="hasPriceRange" class="catalog__available-item">
            {{ $t('yacht.price') }}: {{ priceRangeLabel }}
            <img src="/images/close-second.svg" @click="removeParam('price')" alt="Close icon"/>
          </div>
          <div v-show="hasCabins" class="catalog__available-item">
            {{ $t('yacht.cabins') }}: {{ cabinsLabel }}
            <img src="/images/close-second.svg" @click="removeParam('cabins')" alt="Close icon"/>
          </div>
          <div v-show="hasPeople" class="catalog__available-item">
            {{ $t('yacht.people') }}: {{ peopleLabel }}
            <img src="/images/close-second.svg" @click="removeParam('people')" alt="Close icon"/>
          </div>
          <div v-show="hasBerths" class="catalog__available-item">
            {{ $t('yacht.berths') }}: {{ berthsLabel }}
            <img src="/images/close-second.svg" @click="removeParam('berths')" alt="Close icon"/>
          </div>
          <div v-show="hasLength" class="catalog__available-item">
            {{ $t('yacht.length') }}: {{ lengthLabel }}
            <img src="/images/close-second.svg" @click="removeParam('length')" alt="Close icon"/>
          </div>
          <div v-show="hasYear" class="catalog__available-item">
            {{ $t('yacht.year') }}: {{ yearLabel }}
            <img src="/images/close-second.svg" @click="removeParam('year')" alt="Close icon"/>
          </div>
          <div v-show="hasWc" class="catalog__available-item">
            WC: {{ wcLabel }}
            <img src="/images/close-second.svg" @click="removeParam('wc')" alt="Close icon"/>
          </div>
        </div>
        <div class="catalog__mobile-nav">
          <a class="page-nav__link catalog__filter" href="#"><span>{{ $t('actions.filter') }}</span><img src="/images/filter.svg"></a>
          <a class="page-nav__link" href="javascript:void(0)" @click="openModalLocation">
            <span>Map</span>
            <img src="/images/map.svg" alt="Map icon">
          </a>
          <div class="page-nav__link catalog__categories"><span>{{ $t('actions.sort') }}</span><img src="/images/categories.svg">
          </div>
        </div>
        <LoadSpinner v-if="!getYachts.length"/>
        <div v-else class="catalog__wrapper">
          <div class="tabs">
            <ul class="tab-links">
              <li :class="{ active: currentSort === '' }">
                <a href="javascript:void(0)" @click="changeSort('')">{{ $t('actions.recommended') }}</a>
              </li>
              <li :class="{ active: currentSort === 'highest_price' }">
                <a href="javascript:void(0)" @click="changeSort('highest_price')">{{ $t('actions.sort_by_highest_price') }}</a>
              </li>
              <li :class="{ active: currentSort === 'lowest_price' }">
                <a href="javascript:void(0)" @click="changeSort('lowest_price')">{{ $t('actions.sort_by_lowest_price') }}</a>
              </li>
            </ul>
            <div class="tab-content" id="tab1">
              <BoatCard v-for="yacht in getYachts" :yacht="yacht" :key="yacht.id"/>
              <div class="pagination">
                <template v-for="(page, index) in getLinks" :key="page">
                  <a v-if="index===0" class="pagination__prev" @click.prevent="updateYachts(getCurrentPage-1)"
                     :class="{ disabled: !getPrevPageUrl }" href="javascript:void(0);">
                    <img src="/images/prev.svg" alt="Prev arrow">
                  </a>
                  <a v-else-if="index===getLinks.length-1 " class="pagination__next"
                     @click.prevent="updateYachts(getCurrentPage+1)" :class="{ disabled: !getNextPageUrl }"
                     href="javascript:void(0);">
                    <img src="/images/next.svg" alt="Next arrow">
                  </a>
                  <a v-else class="pagination__link"
                     @click.prevent="updateYachts(page.label)"
                     :class="{ active: page.label == getCurrentPage }" href="javascript:void(0);">
                    {{ page.label }}
                  </a>

                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overLay" v-if="openLocation" @click="closeModalLocation"></div>
  <BoatsLocation v-if="openLocation" :points="points" @closeModal="closeModalLocation"/>
</template>

<script>
import BoatCard from "@/components/partials/BoatCard.vue";
import FullSearch from "@/components/partials/FullSearch.vue";
import {mapActions, mapGetters} from "vuex";
import BoatsLocation from "@/components/partials/modals/BoatsLocation.vue";
import BreadCrumbs from "@/components/partials/BreadCrumbs.vue";
import Preloader from "@/components/globals/Preloader.vue";
import LoadSpinner from "@/components/globals/LoadSpinner.vue";

export default {
  name: "SearchView",
  components: {LoadSpinner, Preloader, BreadCrumbs, BoatsLocation, FullSearch, BoatCard},
  data() {
    return {
      yachts: [],
      openLocation: false,
      currentSort: '',
      queryParams: new URLSearchParams(window.location.search),
      forceUpdateSearch: false,
      contacts: {
        phone:'',
        email:''
      }
    };
  },
  computed: {
    ...mapGetters(["getYachts", "getCurrentPage", "getLastPage", "getNextPageUrl", "getPrevPageUrl", "getLinks", "getCurrentPage", "getDestinations","getSelectedDestination", "getLimits" , "getTotal"]),
    points() {
      return this.getYachts.map(yacht => {
        if (yacht.latitude && yacht.longitude) {
          return {
            latitude: +yacht.latitude,
            longitude: +yacht.longitude
          };
        }
      });
    },
    selectedDestination() {
      return this.getDestinations.find(destination => destination.slug === this.queryParams.get('destination'));
    },
    hasPriceRange() {
      return this.checkParam('price');
    },
    hasCabins() {
      return this.checkParam('cabins');
    },
    hasPeople() {
      return this.checkParam('people');
    },
    hasBerths() {
      return this.checkParam('berths');
    },
    hasLength() {
      return this.checkParam('length');
    },
    hasYear() {
      return this.checkParam('year');
    },
    hasWc() {
      return this.checkParam('wc');
    },
    priceRangeLabel() {
      return this.getParamLabel('price')+' €';
    },
    cabinsLabel() {
      return this.getParamLabel('cabins');
    },
    peopleLabel() {
      return this.getParamLabel('people');
    },
    berthsLabel() {
      return this.getParamLabel('berths');
    },
    lengthLabel() {
      return this.getParamLabel('length')+' m';
    },
    yearLabel() {
      return this.getParamLabel('year');
    },
    wcLabel() {
      return this.getParamLabel('wc');
    },
    selectedDestinationsList() {
      const urlParams = new URLSearchParams(window.location.search);
      const destinationsParam = urlParams.get('destinations');
      if (destinationsParam) {
        const destinationsArray = decodeURIComponent(destinationsParam).split(',');
        const filteredDestinations = this.getDestinations
            .filter(destination => destinationsArray.includes(destination.slug))
            .map(destination => destination.name);

        const count = filteredDestinations.length;
        if (count === 0) {
          return "Sailing Area";
        } else if (count === 1) {
          return filteredDestinations[0];
        } else if (count === 2) {
          return filteredDestinations.join(" and ");
        } else {
          return filteredDestinations.slice(0, count - 1).join(", ") + " and " + filteredDestinations[count - 1];
        }
      }
      return "test";
    },
    orderByParam() {
      return this.$route.query.order_by || ''; // Default to an empty string if `order_by` is not present
    },
  },
  methods: {
    ...mapActions(["fetchYachts"]),
    openModalLocation() {
      document.body.style.overflow = "hidden";
      this.openLocation = true;
    },
    closeModalLocation() {
      document.body.style.overflow = "";
      this.openLocation = false;
    },
    checkParam(param) {
      return this.queryParams.has(param);
    },
    getParamLabel(param) {
      if (this.queryParams.has(param)) {
        const values = this.queryParams.get(param).split(",");
        return `${values[0]}`;
      }
      return "";
    },
    removeParam(param) {
      this.queryParams.delete(param);
      window.history.replaceState({}, "", `${window.location.pathname}?${this.queryParams}`);
      this.updateParams();
      this.fetchYachts();
      this.$nextTick(() => {
        this.forceUpdateSearch = !this.forceUpdateSearch;
      });
    },
    updateParams() {
      this.queryParams = new URLSearchParams(window.location.search);
    },
    updateYachts(page) {
      const queryParams = new URLSearchParams();
      const params = new URLSearchParams(window.location.search);
      for (const [key, value] of params.entries()) {
        queryParams.append(key, value);
      }
      queryParams.set("page", page);

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

      window.history.replaceState({}, "", newUrl);
      this.fetchYachts(page);
      window.scrollTo(0, 0);
    },
    updateSelectedDestination(destinationSlug) {
      this.selectedDestination = this.getDestinations.find(destination => destination.slug === destinationSlug);
    },
    changeSort(sort) {
      this.currentSort = sort;
      const queryParams = new URLSearchParams(window.location.search);
      if (sort) {
        queryParams.set('order_by', sort);
      } else {
        queryParams.delete('order_by');
      }
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

      window.history.pushState(null, '', newUrl);
      this.fetchYachts();
    },
    async fetchContacts() {
      const URI_API = process.env.VUE_APP_API_URL;
      const url =  `${URI_API}/settings`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        this.contacts = {
          phone: data.phone || this.contacts.phone,
          email: data.email || this.contacts.email,
        };
      } catch (error) {
        console.error(error);
      }
    }
  },
  watch: {
    '$route.query.destinations'() {
      this.updateParams();
      this.updateSelectedDestination();
    },
    orderByParam(newOrderBy) {
      this.currentSort = newOrderBy;
    },
  },
  async created() {
    await this.fetchYachts();
    await this.fetchContacts();
    this.updateParams();
    this.currentSort = this.orderByParam;

  }
};
</script>

<style scoped>
.overLay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
}

@media (max-width: 767px) {
  .overLay {
    padding: 20px;
  }
}
</style>
