<template>
  <LoadingIndicator v-if="showLoadingIndicator" />
  <div class="container">
    <div class="page-nav">
      <div class="breadcrumbs"><a class="breadcrumbs__link" href="#">Home</a><span
          class="breadcrumbs__separator">/</span><span class="breadcrumbs__current">Blog</span></div>
    </div>
    <div class="page-head">
      <h1 class="page-head__title">{{ pageData.title }}</h1>
    </div>
    <div class="blog">
      <div class="blog__nav">
        <div class="blog__nav-title" @click="resetCategories">Categories</div>
        <a
            v-for="category in blogCategories"
            @click="setActiveCategory(category)"
            :key="category.id"
            class="blog__nav-link"
            :class="{ active: isActiveCategory(category) }"
            href="#"
        >
          {{ category.title }}
        </a>
      </div>
      <div class="blog__wrapper">
        <a v-for="blog in paginatedBlogs" :key="blog.id" class="blog__item" :href="`/${$i18n.locale}/${blog.slug}`">
          <div class="blog__item-image">
            <img :src="getBlogImage(blog)" :alt=blog.title :title=blog.title>
          </div>
          <div class="blog__item-info">
            <div class="blog__item-title">{{ blog.title }}
            </div>
            <div class="blog__item-text"> {{ blog.mainContent.about }}
            </div>
            <div class="blog__item-actions">
              <div class="blog__item-date"><span>{{ blog.categoryTitle }}</span><span>{{ blog.created_at }}</span></div>
              <div class="btn"><span>More</span></div>
            </div>
          </div>
        </a>

        <div v-if="filteredBlogs.length > 5" class="pagination">
          <a class="pagination__prev" href="#" @click.prevent="changePage(currentPage - 1)">
            <img src="/images/prev.svg">
          </a>
          <a
              v-for="page in totalPages"
              :key="page"
              class="pagination__link"
              :class="{ active: currentPage === page }"
              href="#"
              @click.prevent="changePage(page)"
          >
            {{ page }}
          </a>
          <a class="pagination__next" href="#" @click.prevent="changePage(currentPage + 1)">
            <img src="/images/next.svg">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingIndicator from "@/components/globals/LoadingIndicator.vue";
import {scrollToElement} from "@/utilities/service";
import {API_URL} from "@/constants";

export default {
  name: 'BlogView',
  components: {LoadingIndicator},
  data() {
    return {
      pageData: {
        mainContent: null
      },
      content: {},
      blogCategories: [],
      filteredBlogs: [],
      activeCategory: "",
      currentPage: 1,
      itemsPerPage: 5,
      showLoadingIndicator: true
    };
  },
  computed: {
    ...mapGetters(['getCurrentLanguage']),
    totalPages() {
      return Math.ceil(this.filteredBlogs.length / this.itemsPerPage);
    },
    paginatedBlogs() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredBlogs.slice(start, end);
    }
  },
  methods: {
    scrollToElement,
    async fetchBlogListingPage() {
      const locale = this.$i18n.locale;

      const url = `${API_URL}/blogs/` + locale;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.content = data;
        this.blogCategories = data.blogCategories;
        this.content.blogList = data.blogList.map(post => {
          const category = this.blogCategories.find(cat => cat.id === post.category_id);
          return { ...post, categoryTitle: category ? category.title : 'Interesting' };
        });
        this.filteredBlogs = this.content.blogList;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchBlogContent() {
      const url = `${API_URL}/page/en/blog-page`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.pageData = data;
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(()=>{
          this.showLoadingIndicator= false;
        }, 700)
      }
    },
    setActiveCategory(category) {
      this.activeCategory = category;
      this.filterBlogsByCategory();
    },
    isActiveCategory(category) {
      return this.activeCategory && this.activeCategory.id === category.id;
    },
    filterBlogsByCategory() {
      if (this.activeCategory) {
        this.filteredBlogs = this.content.blogList.filter(
            blog => blog.category_id === this.activeCategory.id
        );
      } else {
        this.filteredBlogs = this.content.blogList;
      }
      this.currentPage = 1;
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.scrollToElement('page-nav');
      }
    },
    getBlogImage(blog) {
      if (!blog.mainContent.image) {
        return '/images/blog-1.png';
      }

      // const width = window.innerWidth;
      //
      // if (width <= 400) {
      //   return blog.mainContent.image.thumb || blog.mainContent.image['400'] || blog.mainContent.image['original'];
      // } else if (width <= 800) {
      //   return blog.mainContent.image['400'] || blog.mainContent.image['800'];
      // } else {
        return blog.mainContent.image['800'] || blog.mainContent.image['original'];
      // }
    },
    resetCategories(){
      this.filteredBlogs = this.content.blogList;
    }
  },
  async created() {
    await this.fetchBlogListingPage();
    await this.fetchBlogContent();
  },
  watch: {
    getCurrentLanguage(newLang, oldLang) {
      this.fetchBlogListingPage();
      this.fetchBlogContent();
    }
  }
}
</script>