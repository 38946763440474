import axios from 'axios';

const URI_API = process.env.VUE_APP_API_URL;

const state = {
    equipments: []
};

const mutations = {
    SET_EQUIPMENTS(state, equipments) {
        state.equipments = equipments;
    }
};

const actions = {
    async fetchEquipments({ commit }) {
        try {
            const url = `${URI_API}/equipments`;
            const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenBearer}`
                }
            });
            const data = response.data;

            console.log('eq',data)
            commit('SET_EQUIPMENTS', data);
        } catch (error) {
            console.error('Error fetching equipments:', error);
        }
    }
};

const getters = {
    getEquipments: state => state.equipments
};

export default {
    state,
    mutations,
    actions,
    getters
};
