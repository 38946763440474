<script>
import Select2 from "vue3-select2-component";
import SideSearchFormButton from "@/components/partials/SideSearchFormButton.vue";
import BoatViewMap from "@/components/partials/modals/BoatViewMap.vue";
import {mapActions, mapGetters} from "vuex";
import BreadCrumbs from "@/components/partials/BreadCrumbs.vue";
import {calculateProductPrice, getImageSrc, scrollToElement, toggleFavorite} from "@/utilities/service";
import BaseMapFrame from "@/components/partials/modals/BaseMapFrame.vue";
import LoadingIndicator from "@/components/globals/LoadingIndicator.vue";
import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";
import RequestPrice from "@/components/partials/modals/RequestPrice.vue";
import {addToFavorites, removeFromFavorites} from "@/api";
import $ from "jquery";

export default {
  name: "BoatView",
  components: {RequestPrice, LoadingIndicator, BaseMapFrame, BreadCrumbs, BoatViewMap, SideSearchFormButton, Select2},
  data() {
    return {
      showLoadingIndicator: true,
      selectedPeriod: {},
      includedExtrasPrice: 0,
      excludedExtrasPrice: 0,
      showMap: false,
      isFavoriteRequested: false,
      localFavorites: JSON.parse(localStorage.getItem('favorites')) || [],
      flexibleCancellation: false,
      showRequestForm: false,
      currentParams: {
        date_start: null,
        date_end: null
      }
    }
  },
  computed: {
    ...mapGetters(["getSelectedYacht", "getEquipments", "getUser", "getToken"]),
    dateRange() {
      const checkIn = this.currentParams.date_start;
      const checkOut = this.currentParams.date_end;
      return {
        start: this.formatDate(checkIn),
        end: this.formatDate(checkOut),
        daysDifference: this.calculateDaysDifference(checkIn, checkOut)
      };
    },
    allImages() {
      if (this.getSelectedYacht.galleryUrls && this.getSelectedYacht.galleryUrls.length > 0) {
        return [this.getSelectedYacht.mainImageUrls, ...this.getSelectedYacht.galleryUrls];
      }
      return this.getSelectedYacht.mainImageUrls ? [this.getSelectedYacht.mainImageUrls] : [];
    },
    getFlagClass() {
      if (this.getSelectedYacht.base && this.getSelectedYacht.base.country.short) {
        return `fi icon-flag fi-${this.getSelectedYacht.base.country.short.toLowerCase()}`;
      }
      return '';
    },
    initialPrice() {
      let result = 0;

      if (this.getSelectedYacht && !this.selectedPeriod && this.getSelectedYacht.period_price && this.getSelectedYacht.period_price.length) {
        result = this.getSelectedYacht.period_price[0].price;
      } else {
        result = this.selectedPeriod && this.selectedPeriod.price;
      }

      return result;
    },
    summaryPrice() {
      const initialPrice = Number(this.initialPrice);
      const includedExtrasPrice = Number(this.includedExtrasPrice);
      let totalPrice;

      if (this.flexibleCancellation) {
        totalPrice = 1.08 * (initialPrice + includedExtrasPrice);
      } else {
        totalPrice = initialPrice + includedExtrasPrice;
      }

      return Math.round(totalPrice);
    },
    obligatoryPayableInBase() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if (!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && prod.payable_in_base);
    },
    obligatoryPayableImmediately() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if (!products.length) return [];

      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && !prod.payable_in_base);
    },
    optionalPayableInBase() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if (!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && prod.payable_in_base);
    },
    optionalPayableImmediately() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if (!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && !prod.payable_in_base);
    },
    isFavorite() {
      if (!this.getUser || !this.getUser.authentificated) {
        if (!this.localFavorites) return false;
        return this.localFavorites && this.localFavorites.some(slug => slug === this.getSelectedYacht.slug);
      } else if (this.getUser) {
        return this.getUser.favorites && this.getUser.favorites.some(slug => slug === this.getSelectedYacht.slug);
      } else {
        return false;
      }
    },
  },
  methods: {
    getImageSrc,
    toggleFavorite,
    scrollToElement,
    addToFavorites,
    removeFromFavorites,
    ...mapActions(["fetchSelectedYacht", "fetchEquipments", "getUserInfo", "fetchFavouriteYachts", "updatePeriodPrices"]),
    closeModal() {
      this.showMap = false;
      this.showRequestForm = false;
      document.body.style.overflow = '';

    },
    openModal() {
      this.showMap = true;
      document.body.style.overflow = 'hidden';
    },
    openModalQuoteRequest() {
      this.showRequestForm = true;
      document.body.style.overflow = 'hidden';
    },
    proceedReserveYacht() {
      if (!this.selectedPeriod) {
        this.animateAttention();
        this.scrollToElement('sidebar__description');
        return;
      }

      const currentUrl = new URL(window.location.href);
      const params = new URLSearchParams(currentUrl.search);

      const locale = this.$i18n.locale;
      const yachtSlug = this.getSelectedYacht.slug;
      let newUrl = `/${locale}/details/${yachtSlug}`;

      // Append all search parameters to the new URL
      params.forEach((value, key) => {
        newUrl += (newUrl.includes('?') ? '&' : '?') + `${key}=${value}`;
      });

      window.location.href = newUrl;
    },
    animateAttention() {
      const notice = this.$refs.availabilityNotice;
      const slider = this.$refs.availabilitySlider;

      notice.classList.add('highlight');
      slider.classList.add('highlight-bg');

      setTimeout(() => {
        notice.classList.remove('highlight');
        slider.classList.remove('highlight-bg');
      }, 1500);
    },
    formatDate(dateStr) {
      if (!dateStr) {
        return {
          dayWeek: "",
          month: "",
          year: ""
        };
      }

      const date = new Date(dateStr);
      const optionsDay = {weekday: 'long', day: 'numeric'};
      const optionsMonthYear = {month: 'long', year: 'numeric'};

      const dayFormatted = date.toLocaleDateString('en-US', optionsDay);
      const monthYearFormatted = date.toLocaleDateString('en-US', optionsMonthYear);

      let [dayOfWeek, dayNumber] = dayFormatted.split(', ');
      const [monthName, yearNumber] = monthYearFormatted.split(' ');
      dayOfWeek = dayOfWeek.split(" ").reverse().join(", ");

      return {
        dayWeek: dayOfWeek,
        month: monthName,
        year: yearNumber
      };
    },
    groupEquipmentByGroupId() {
      const grouped = {};
      if (this.getSelectedYacht) {
        this.getSelectedYacht.equipment.forEach(item => {
          if (!grouped[item.group_id]) {
            grouped[item.group_id] = [];
          }
          grouped[item.group_id].push(item);
        });

        this.getSelectedYacht.groupedEquipment = grouped;
      }
    },
    normalizeDate(inputDate) {
      const date = new Date(inputDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    updateReservedDateRange(start, end, period) {
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);
      let currentStart = start.slice(0, 10);
      let currentEnd = end.slice(0, 10);
      params.set('date_start', currentStart);
      params.set('date_end', currentEnd);
      this.selectedPeriod = period;
      // this.initialPrice = period.price;
      history.replaceState(null, '', url.pathname + '?' + params.toString());
      this.currentParams = {
        date_start: currentStart,
        date_end: currentEnd
      }
    },
    calculateDaysDifference(startStr, endStr) {
      // Parse the date strings into Date objects
      const startDate = new Date(startStr);
      const endDate = new Date(endStr);

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = endDate - startDate;

      // Convert the difference from milliseconds to days
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      return Math.round(differenceInDays);
    },
    toggleExtraProduct(product) {
      const url = new URL(window.location.href);
      const params = url.searchParams;
      if (product === 'fc') {
        if (params.has('fc')) {
          params.delete('fc');
        } else {
          params.set('fc', 'true');
          this.flexibleCancellation = true;
        }

        history.replaceState(null, '', url.toString());
        return;
      }
      if (params.has(product.id.toString())) {
        params.delete(product.id.toString());
        product.checked = false;
        if (product.payable_in_base) {
          this.excludedExtrasPrice -= Number(this.calculateProductPrice(product));
        } else {
          this.includedExtrasPrice -= Number(this.calculateProductPrice(product));
        }
      } else {
        params.set(product.id.toString(), 'true');
        product.checked = true;
        if (product.payable_in_base) {
          this.excludedExtrasPrice += Number(this.calculateProductPrice(product));
        } else {
          this.includedExtrasPrice += Number(this.calculateProductPrice(product));
        }
      }

      history.replaceState(null, '', url.toString());
    },
    updateExtrasWithChecked(extras) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('fc')) {
        this.flexibleCancellation = true;
      }
      extras.forEach(extra => {
        extra.checked = urlParams.has(extra.id.toString());
        if (extra.checked) {
          if (extra.payable_in_base) {
            this.excludedExtrasPrice += Number(this.calculateProductPrice(extra));
          } else {
            this.includedExtrasPrice += Number(this.calculateProductPrice(extra));
          }
        }
      });
    },
    calculateProductPrice,
    initSwiper() {
      if (this.allImages.length === 0) {
        return;
      }
      var gallery = new Swiper('.card__slider', {
        modules: [Navigation, Pagination],
        spaceBetween: 10,
        autoHeight: true,
        navigation: {
          nextEl: '.card__slider .swiper-button-next',
          prevEl: '.card__slider .swiper-button-prev',
        },
        pagination: {
          el: '.card__slider .swiper-pagination',
          type: 'fraction',
        },
      });

      var availability = new Swiper(".availability__slider", {
        modules: [Navigation],
        slidesPerView: "auto",
        navigation: {
          nextEl: ".availability__slider .swiper-button-next",
          prevEl: ".availability__slider .swiper-button-prev",
        },
      });
    },
    async performFavorites(yacht) {
      if (this.isFavoriteRequested) {
        return;
      }
      this.isFavoriteRequested = true;

      if (!this.getUser.authentificated) {
        this.toggleFavorite(yacht);
        this.localFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
      } else {
        if (this.getUser.favorites.includes(yacht.slug)) {
          this.removeFromFavorites(yacht.slug, this.getToken).then(() => {
            this.getUser.favorites = this.getUser.favorites.filter(fav => fav.slug !== yacht.slug);
          });
        } else {
          await this.addToFavorites(yacht.slug, this.getToken);
        }
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
          await this.getUserInfo({accessToken: authToken, innerUser: true});
        }
      }
      this.$emit('trigger-update-in-header');
      this.isFavoriteRequested = false;
      await this.fetchFavouriteYachts();
    },
    animateAndCopy() {
      // Animate button
      const link = this.$el.querySelector('.page-nav__link');
      link.classList.add('animated');

      // Animate icon
      const icon = this.$refs.shareIcon;
      icon.classList.add('rotated');

      const iconMobile = this.$refs.shareIconMobile;
      iconMobile.classList.add('rotated');

      // Remove animation classes after 0.5 seconds
      setTimeout(() => {
        link.classList.remove('animated');
        icon.classList.remove('rotated');
        iconMobile.classList.remove('rotated');
      }, 500);

      // Copy URL to clipboard
      this.copyToClipboard(window.location.href);
    },
    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed';
      textarea.style.opacity = '0';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$notify({
        title: 'Success',
        text: 'The link has being copied to clipboard',
        type: 'warn'
      })
    },
    handleTabs() {
      $(".card__faq-item").off('click').on('click', function () {
        $(".card__faq-item")
            .not(this)
            .removeClass("active")
            .find(".card__faq-text")
            .slideUp();
        $(this).toggleClass("active").find(".card__faq-text").slideToggle();
      });
    }
  },
  async mounted() {
    await this.fetchEquipments();
    await this.fetchSelectedYacht().then((response) => {
      this.groupEquipmentByGroupId();
      let selectedProduct;
      const products = this.getSelectedYacht.products;

      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }
      if (selectedProduct && selectedProduct.extras) {
        this.updateExtrasWithChecked(selectedProduct.extras);
      }
      this.showLoadingIndicator = false;
    });

    await this.updatePeriodPrices(this.getSelectedYacht.id);

    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    const checkIn = params.get('date_start');
    const checkOut = params.get('date_end');
    this.currentParams = {
      date_start: checkIn,
      date_end: checkOut
    };

    window.scrollTo(0, 0);


    const initSwiperWhenReady = () => {
      const sliderElement = this.$el.querySelector('.card__slider');
      if (sliderElement) {
        this.initSwiper();
      } else {
        setTimeout(initSwiperWhenReady, 100);
      }
    };
    this.$nextTick(initSwiperWhenReady);

    console.log(this.getSelectedYacht);
    this.handleTabs();
  },

  updated() {
    this.handleTabs();
  },

  watch: {
    getSelectedYacht(newVal) {
      if (newVal) {
        const params = new URLSearchParams(window.location.search);
        const checkIn = params.get('date_start');
        this.selectedPeriod = newVal.period_price?.find(item => item.date_from.startsWith(checkIn));
      }
    },
  },
}
</script>

<template>
  <LoadingIndicator v-if="showLoadingIndicator"/>
  <div class="container" v-else>
    <div class="page-nav">
      <BreadCrumbs v-if="getSelectedYacht.base" :base=getSelectedYacht.base />
      <div class="page-nav__actions page-nav__actions__desktop">
        <a class="page-nav__link copy-pointer" @click.prevent="animateAndCopy" title="Click to copy to clipboard">
          <span>{{ $t('actions.share') }}</span>
          <img src="/images/map.svg" alt="Share icon" ref="shareIcon">
        </a>
        <a class="page-nav__link" href="javascript:void(0)" @click.stop="performFavorites(getSelectedYacht)">
          <span style="margin-right:5px">{{ $t('actions.save') }}</span>
          <svg width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <path
                :d="isFavorite ? 'M24.9707 6.70088C22.7028 4.43304 19.0274 4.43304 16.6813 6.70088L15.0391 8.34305L13.3969 6.70088C11.0512 4.43304 7.37571 4.43304 5.10787 6.70088C2.84 8.96874 2.84 12.6442 5.10787 14.9121L15.0391 25L24.9707 14.9121C27.2385 12.6442 27.2385 8.96874 24.9707 6.70088Z' : 'M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246'"
                :fill="isFavorite ? '#BF8D37' : 'none'"
                stroke="#BF8D37"
                stroke-width="3"
                stroke-miterlimit="22.9256"
            ></path>
          </svg>
        </a>
        <!--        <a class="page-nav__link" @click="openModal">-->
        <!--          <span>Map</span>-->
        <!--          <img src="/images/map.svg" alt="Map button icon">-->
        <!--        </a>-->
      </div>
    </div>
    <div class="catalog">
      <div class="sidebar">
        <div class="sidebar__close"><img src="/images/close.svg" alt="Close icon"></div>
        <SideSearchFormButton />
        <div class="sticky">
          <div class="booking">
            <div class="sidebar__title">{{ $t('yacht.your_booking_details') }}</div>
            <div class="details-item" v-if="dateRange.start.dayWeek">
              <div class="details-item__name">{{ $t('yacht.yacht_pick_up') }}</div>
              <div class="details-item__descr">{{ dateRange.start.dayWeek }} {{ dateRange.start.month }}
                {{ dateRange.start.year }}
              </div>

            </div>
            <div class="details-item" v-if="dateRange.end.dayWeek">
              <div class="details-item__name">{{ $t('yacht.yacht_drop_off') }}</div>
              <div class="details-item__descr">{{ dateRange.end.dayWeek }} {{ dateRange.end.month }}
                {{ dateRange.end.year }}
              </div>
            </div>
            <div class="details-item" v-if="dateRange.daysDifference">
              <div class="details-item__name">{{ $t('yacht.total_length_of_rental') }}</div>
              <div class="details-item__descr">{{ dateRange.daysDifference }} {{ $t('interface.days') }}</div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_pick-up_and_drop-off_address') }}</div>
              <div class="details-item__descr">{{ getSelectedYacht.base?.name }}</div>
            </div>
            <!--            <div class="details-item">-->
            <!--              <div class="details-item__name">Charter company</div>-->
            <!--              <div class="details-item__descr">{{ getSelectedYacht.company?.name }}</div>-->
            <!--            </div>-->
          </div>
          <div class="excluded" v-if="excludedExtrasPrice">
            <div class="sidebar__title">{{
                $t('yacht.excluded_charges')
              }}<span>{{ $t('yacht.to_be_paid_at_the_yacht_pick-up') }}</span></div>

            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.selected_options') }}</div>
              <div class="details-item__descr">{{ excludedExtrasPrice }} €</div>
            </div>

          </div>

          <div class="excluded" v-if="includedExtrasPrice">
            <div class="sidebar__title">{{ $t('yacht.included_charges') }}<span>{{
                $t('yacht.added_to_summary_charge')
              }}</span></div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.selected_options') }}</div>
              <div class="details-item__descr">{{ includedExtrasPrice }} €</div>
            </div>

          </div>
          <div class="summary" v-if="initialPrice">

            <div class="sidebar__title">{{ $t('yacht.price_summary') }}</div>
            <div class="details-item " v-if="flexibleCancellation">
              <div class="details-item__name details-item__small details-item__name details-item__small-flex">
                <span>{{ $t('yacht.flexible_cancellation') }}</span> <span>{{
                  Math.round(initialPrice * 0.08)
                }} {{ getSelectedYacht.currency }}</span></div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_rental') }}</div>
              <div class="details-item__descr">{{ initialPrice }} {{
                  getSelectedYacht.currency === 'EUR' ? "€" : "$"
                }}
              </div>
            </div>
            <div class="summary__rental" v-if="initialPrice != summaryPrice"><span>{{
                $t('yacht.total_rental_charge')
              }}</span><span>{{ summaryPrice }} €</span></div>
          </div>
          <div class="details__actions">
            <div class="details__actions-buttons">
              <div class="details__actions-buttons-item">
                <a class="btn btn--bordered" v-if="getSelectedYacht.country"
                   :href="`/${$i18n.locale}/search?destinations=${getSelectedYacht.country.slug|| 'italy'}&type=any_type&date_start=2024-08-03&date_end=2024-08-10`">New
                  search</a>
              </div>
              <div class="details__actions-buttons-item">
                <a v-if="initialPrice" class="btn" href="javascript:void(0)" @click="proceedReserveYacht">
                  <span>{{ $t('actions.reserve') }}</span>
                </a>
                <a v-else class="btn btn--bordered" @click="openModalQuoteRequest">
                  <span>{{ $t('actions.send_request') }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <!-- <div class="catalog__mobile-nav">
          <a class="page-nav__link catalog__filter" href="#"><span>{{ $t('actions.filter') }}</span>
            <img src="/images/filter.svg" alt="filter"></a>
          <div class="page-nav__link catalog__categories"><span>{{ $t('actions.categories') }}</span>
            <img src="/images/categories.svg" alt="filter category">
          </div>
        </div> -->
        <div class="card__head" v-if="getSelectedYacht && getSelectedYacht.model">
          <span :class="getFlagClass"></span>
          <h1 class="card__title" v-cloak>
            {{ getSelectedYacht.model && getSelectedYacht.model.name }} | {{ getSelectedYacht.name }}
          </h1>
        </div>
        <div class="card__place" v-if="getSelectedYacht && getSelectedYacht.base">
          {{ getSelectedYacht.base && getSelectedYacht.base.city }},
          {{ getSelectedYacht.base && getSelectedYacht.base.name }}
        </div>
        <div class="card__gallery">
          <div class="page-nav__actions page-nav__actions__mobile">
            <a class="page-nav__link" @click.prevent="animateAndCopy" title="Click to copy to clipboard">
              <span>Share</span>
              <img src="/images/map.svg" alt="Share icon" ref="shareIconMobile">
            </a>
            <a class="page-nav__link" href="javascript:void(0)" @click.stop="performFavorites(getSelectedYacht)">
              <span style="margin-right:5px">{{ $t('actions.save') }}</span>
              <svg width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <path
                    :d="isFavorite ? 'M24.9707 6.70088C22.7028 4.43304 19.0274 4.43304 16.6813 6.70088L15.0391 8.34305L13.3969 6.70088C11.0512 4.43304 7.37571 4.43304 5.10787 6.70088C2.84 8.96874 2.84 12.6442 5.10787 14.9121L15.0391 25L24.9707 14.9121C27.2385 12.6442 27.2385 8.96874 24.9707 6.70088Z' : 'M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246'"
                    :fill="isFavorite ? '#BF8D37' : 'none'"
                    stroke="#BF8D37"
                    stroke-width="3"
                    stroke-miterlimit="22.9256"
                ></path>
              </svg>
            </a>
          </div>
          <div class="swiper card__slider">
            <div class="swiper-pagination"></div>
            <div class="swiper-wrapper" id="swiper-wrapper">
              <div v-for="(image, index) in allImages" :key="index" class="swiper-slide">
                <img v-if="image" :src="getImageSrc(image)" :alt="getSelectedYacht.model && getSelectedYacht.model.name"
                     :title="getSelectedYacht.model && getSelectedYacht.model.name">
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <!-- <div class="card__slider-nav-wrapper">
            <div class="swiper-button-prev"></div>
            <div class="swiper card__slider-nav">
              <div class="swiper-wrapper">

                <div v-for="(image, index) in allImages" :key="index" class="swiper-slide">
                  <img :src="image.thumb" alt="yacht-view">
                </div>
              </div>
            </div>
            <div class="swiper-button-next"></div>
          </div> -->
        </div>
        <div class="card__lists technical-list">
          <ul>
            <li>
              <div class="card__list-title"><img src="/images/date.svg" alt="Year built">{{ $t('yacht.year') }}</div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.year }}</div>
            </li>
            <li v-if="getSelectedYacht.max_people">
              <div class="card__list-title"><img src="/images/people.svg" alt="Max passengers">{{ $t('yacht.people') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.max_people }}</div>
            </li>
            <li v-if="getSelectedYacht.cabins">
              <div class="card__list-title"><img src="/images/cabins.svg" alt="Cabins amount">{{ $t('yacht.cabins') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.cabins }}</div>
            </li>
          </ul>
          <ul>
            <li>
              <div class="card__list-title">
                <img src="/images/location.svg" alt="Location place">{{ $t('yacht.location') }}
              </div>
              <span></span>
              <div class="card__list-value"> {{ getSelectedYacht.country && getSelectedYacht.country.name }}</div>
            </li>
            <li v-if="getSelectedYacht.berths_note || getSelectedYacht.berths">
              <div class="card__list-title">
                <img src="/images/berths.svg" alt="Berths amount">{{ $t('yacht.berths') }}
              </div>
              <span></span>
              <div class="card__list-value">{{
                  getSelectedYacht.berths_note ? getSelectedYacht.berths_note.replace(/[()]/g, '') : getSelectedYacht.berths
                }}
              </div>
            </li>
            <li v-if="getSelectedYacht.wc">
              <div class="card__list-title"><img src="/images/toilets.svg" alt="WC's amount">{{ $t('yacht.toilets') }}
              </div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.wc }}</div>
            </li>
          </ul>
        </div>
        <div class="card__heading">
          <h2 class="card__heading-name">{{ $t('yacht.other_information') }}</h2>
        </div>
        <div class="card__lists other-list">
          <ul>
            <li v-if="getSelectedYacht.type">
              <div class="card__list-title">{{ $t('yacht.type_of_boat') }}:</div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.type && getSelectedYacht.type.name }}</div>
            </li>
            <li v-if="getSelectedYacht.shipyard">
              <div class="card__list-title">{{ $t('yacht.brand') }}:</div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.shipyard.name }}</div>
            </li>
            <li>
              <div class="card__list-title">{{ $t('yacht.beam') }} / {{ $t('yacht.draught') }}:</div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.beam || 'NA' }} m / {{
                  getSelectedYacht.draught || 'NA'
                }}
                m
              </div>
            </li>
            <li v-if="getSelectedYacht.water_capacity">
              <div class="card__list-title">{{ $t('yacht.water_capacity') }}:</div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.water_capacity }} l</div>
            </li>
          </ul>
          <ul>
            <li v-if="getSelectedYacht.model">
              <div class="card__list-title">{{ $t('yacht.model') }}:</div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.model && getSelectedYacht.model.name }}</div>
            </li>
            <li v-if="getSelectedYacht.length">
              <div class="card__list-title">{{ $t('yacht.length') }}:</div>
              <span></span>
              <div class="card__list-value">{{ parseFloat(getSelectedYacht.length).toFixed(2) }} m</div>
            </li>
            <li v-if="getSelectedYacht.fuel_capacity">
              <div class="card__list-title">{{ $t('yacht.fuel_capacity') }}:</div>
              <span></span>
              <div class="card__list-value">{{ getSelectedYacht.fuel_capacity }} l</div>
            </li>
            <li>
              <div class="card__list-title">{{ $t('yacht.inside_showers') }}:</div>
              <span></span>
              <div class="card__list-value">{{ $t('interface.yes') }}</div>
            </li>
          </ul>
        </div>

        <div class="card__heading">
          <h2 class="card__heading-name">{{ $t('yacht.equipment') }}</h2>
        </div>
        <div class="card__lists equipment-list">
          <template v-for="group in getEquipments" :key="group.id" style="width: 100%">
            <template v-if="getSelectedYacht.groupedEquipment && getSelectedYacht.groupedEquipment[group.id]">
              <div style="width:100%;display: flex;justify-content: space-between">
                <div class="card__list-title"
                     style="width: 80%; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
                  <div style="display: flex;width: 100%">{{ group.name }}<span class="divider"></span></div>
                </div>
                <span class="divider"></span>
                <div style="width: 100%;display: flex;flex-wrap: wrap">
                  <div class="card__list-value" v-for="(item,index) in getSelectedYacht.groupedEquipment[group.id]"
                       :key="item.id" style="display: flex">
                    {{ item.name }}
                    <div v-if="index !== getSelectedYacht.groupedEquipment[group.id].length - 1"
                         style="width:15px;color:#a8a8a8;text-align: center">|
                    </div>

                  </div>

                </div>

              </div>
            </template>
          </template>
        </div>


        <div class="sidebar__description">
          <div class="sidebar__description-title">{{ $t('yacht.description') }}</div>
          <div class="sidebar__description-text">
            <p>{{ getSelectedYacht.cabins }}-cabin sailing yacht
              {{ getSelectedYacht.model && getSelectedYacht.model.name }} - New was built in {{ getSelectedYacht.year }}
              and it is docked in {{ getSelectedYacht.shipyard && getSelectedYacht.shipyard.name }}.
            </p>
            <p>New can accommodate up to {{ getSelectedYacht.max_people }} people in {{ getSelectedYacht.cabins }}
              double cabins and a saloon with 1 extra bed.</p>
            <p>Sailing yacht New offers {{ getSelectedYacht.wc }} toilets with a shower.</p>
            <p>Boat equipment features classic mainsail, bimini and bow thruster. It also boasts heating, audio system,
              outside speakers and inside speakers. The fully-equipped galley includes an oven, cooker, sink and kitchen
              utensils.</p>
            <p>This sailing yacht is operated by the charter company
              {{ getSelectedYacht.company && getSelectedYacht.company.name }}.</p>
          </div>
        </div>
        <div class="availability">
          <div class="availability__head">
            <h2 class="availability__title">{{ $t('yacht.availability') }}</h2>
            <div v-if="!selectedPeriod" class="availability__notice" ref="availabilityNotice">Please select available
              period
            </div>
          </div>
        </div>
        <div class="availability__slider swiper" ref="availabilitySlider">
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-wrapper">
            <div class="swiper-slide swiper-slide--empty"></div>
            <div class="swiper-slide" v-for="period in getSelectedYacht.period_price" :key="period.id"
                 @click.stop="updateReservedDateRange(period.date_from, period.date_to, period)"
                 :class="{'highlighted-slide': selectedPeriod && (period.id === selectedPeriod.id)}">
              <div class="availability__slider-date">{{ normalizeDate(period.date_from) }} -
                {{ normalizeDate(period.date_to) }}
              </div>
              <div class="availability__slider-status availability__slider-status--available">
                {{ period.available ? $t('yacht.available') : $t('yacht.reserved') }}
              </div>
              <div class="availability__slider-price">{{ period.price + " " + period.currency }}</div>
            </div>
            <div class="swiper-slide swiper-slide--empty"></div>
          </div>
        </div>
        <div class="card__advantages">
          <div class="card__heading">
            <h2 class="card__heading-name">
              {{ getSelectedYacht.model && getSelectedYacht.model.name }} | {{ getSelectedYacht.name }}
            </h2>
            <div class="card__heading-subtitle">{{ $t('yacht.charter_company') }}:
              {{ getSelectedYacht.company && getSelectedYacht.company.name }}
            </div>
          </div>
          <div class="card__advantages-wrapper">
            <div class="card__advantages-col">
              <div class="card__advantages-head">{{ $t('yacht.payment_policies') }}</div>
              <div class="card__advantages-descr">30% {{ $t('yacht.booking_payment') }}</div>
              <div class="card__advantages-descr">{{ $t('yacht.free_cancellation_before') }} 26 {{
                  $t('interface.march')
                }} 2024
              </div>
              <div class="card__advantages-descr">{{ $t('yacht.best_price_on_the_market') }}</div>
            </div>
            <div class="card__advantages-col">
              <div class="card__advantages-head">{{ $t('yacht.price_includes') }}</div>
              <div class="card__advantages-descr"><span>{{ $t('yacht.free') }} </span> {{ $t('yacht.dinghy_engine') }}
              </div>
            </div>
            <div class="card__advantages-col">
              <div class="card__advantages-head">
                <div class="availability__slider-status availability__slider-status--available">{{
                    $t('yacht.available')
                  }}
                </div>
              </div>
              <div class="card__advantages-sale" v-if="selectedPeriod && selectedPeriod.discount !=0">
                <span>- {{ selectedPeriod.discount }}%</span><span>{{
                  selectedPeriod.start_price
                }} {{ getSelectedYacht.currency === 'EUR' ? "€" : "$" }}</span></div>
              <div v-if="initialPrice" class="card__advantages-price">{{ initialPrice }}
                {{ getSelectedYacht.currency === 'EUR' ? "€" : "$" }}
              </div>
              <a v-if="initialPrice" class="btn" @click="proceedReserveYacht"><span>{{
                  $t('actions.reserve')
                }}</span></a>
              <a v-else class="btn btn--bordered" @click="openModalQuoteRequest"><span>{{
                  $t('actions.send_request')
                }}</span></a>
            </div>
          </div>
        </div>


        <div class="services" v-if="obligatoryPayableImmediately.length">
          <div class="card__heading">
            <h2 class="card__heading-name">{{ $t('yacht.services') }}</h2>
            <div class="card__heading-subtitle">{{ $t('yacht.included_in_charge') }}</div>
          </div>

          <label class="services__row" v-for="product in obligatoryPayableImmediately">
            <div class="services__checkbox">
              <div class="checkbox">
                <span class="checkbox__text">{{ product.name }}</span>
              </div>
              <div v-if="product.description" class="services__checkbox-descr">
                {{ product.description }}
              </div>
            </div>
            <div class="services__price">{{ product.price }} {{ product.currency }} {{ product.unit }}</div>
            <div class="services__optional">{{ $t('yacht.mandatory') }}</div>
          </label>

        </div>

        <div class="charges">
          <div class="card__heading">
            <h2 class="card__heading-name">{{ $t('yacht.excluded_charges') }}</h2>
            <div class="card__heading-subtitle">{{ $t('yacht.to_be_paid_at_the_yacht_pick-up') }}</div>
          </div>

          <div class="charges__row" v-for="product in obligatoryPayableInBase" :key="product.id">
            <div class="charges__name">
              <div class="charges__name-title">{{ product.name }}</div>
            </div>
            <div class="charges__price">{{ product.price }} {{ product.currency }}
              {{ product.unit }}
            </div>
            <div class="charges__mandatory">{{ $t('yacht.mandatory') }}</div>
          </div>

        </div>

        <div class="services" v-if="optionalPayableImmediately.length">
          <div class="card__heading">
            <h2 class="card__heading-name">{{ $t('yacht.extras') }}</h2>
            <div class="card__heading-subtitle">{{ $t('yacht.book_now_or_later') }}</div>
          </div>
<!--          TODO: uncomment flexible cancellation when periods and value will be known-->
<!--          <label class="services__row">-->
<!--            <div class="services__checkbox">-->
<!--              <div class="checkbox">-->
<!--                <input type="checkbox" v-model="flexibleCancellation" @click="toggleExtraProduct('fc')">-->
<!--                <span class="checkbox__checkmark"></span>-->
<!--                <span class="checkbox__text">{{ $t('yacht.flexible_cancellation') }}</span>-->
<!--              </div>-->
<!--              <div class="services__checkbox-descr">-->
<!--                {{-->
<!--                  $t('yacht.flexible_cancellation_is_designed_for_cases_when_you_suddenly_need_to_cancel_your_sailing_holiday')-->
<!--                }} {{ $t('yacht.it_also_covers_COVID-19_sickness,_hospitalization_or_forced_quarantine') }}-->
<!--                {{ $t('interface.learn_more') }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="services__price">8% {{ $t('yacht.from_rental_price') }}</div>-->
<!--            <div class="services__optional">{{ $t('yacht.optional') }}</div>-->
<!--          </label>-->


          <label class="services__row" v-for="product in optionalPayableImmediately">
            <div class="services__checkbox">
              <div class="checkbox">
                <input type="checkbox" @click="toggleExtraProduct(product)">
                <span class="checkbox__checkmark"></span>
                <span class="checkbox__text">{{ product.name }}</span>
              </div>
            </div>
            <div class="services__price">{{ product.price }} {{ product.currency }} {{ product.unit }}</div>
            <div class="services__optional">{{ $t('yacht.optional') }}</div>
          </label>
        </div>

        <div class="services">
          <div class="card__heading">
            <h2 class="card__heading-name">{{ $t('yacht.extras') }}</h2>
            <div class="card__heading-subtitle">{{ $t('yacht.to_be_paid_at_the_yacht_pick-up') }}</div>
          </div>
          <label class="services__row" v-for="product in optionalPayableInBase">
            <div class="services__checkbox">
              <div class="checkbox">
                <input type="checkbox" @click="toggleExtraProduct(product)" :checked="product.checked">
                <span class="checkbox__checkmark"></span>
                <span class="checkbox__text">{{ product.name }}</span>
              </div>
            </div>
            <div class="services__price">{{ product.price }} {{ product.currency }} {{ product.unit }}</div>
            <div class="services__optional">{{ $t('yacht.optional') }}</div>
          </label>
        </div>

        <div class="card__info">
          <div class="card__heading">
            <h2 class="card__heading-name">{{ $t('yacht.good_to_know') }}</h2>
          </div>
          <!--          <div class="card__info-company">-->
          <!--            <div class="card__info-company-title">{{ $t('yacht.charter_company') }}</div>-->
          <!--            <div class="card__info-company-descr">-->
          <!--              <div class="card__info-company-name">{{ getSelectedYacht.company && getSelectedYacht.company.name }} | #19-->
          <!--                {{ $t('interface.of') }}-->
          <!--                501 {{ $t('yacht.charter_companies') }} {{ $t('interface.in') }} Spain-->
          <!--              </div>-->
          <!--              <div class="card__info-company-rate">-->
          <!--                <span>{{ $t('yacht.customers_rate') }} {{ getSelectedYacht.company && getSelectedYacht.company.name }}</span><span>4.8</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="card__info-company-address">
            <div class="card__info-company-title">{{ $t('yacht.yacht_pick-up_address') }}</div>
            <div class="card__info-company-address-text">{{ getSelectedYacht.base && getSelectedYacht.base.city }},
              {{ getSelectedYacht.base && getSelectedYacht.base.name }}
              <span :class="getFlagClass"></span>
            </div>
            <div class="card__info-company-map">-
              <BaseMapFrame :base="getSelectedYacht.base" :country="getSelectedYacht.country"/>
            </div>
          </div>
        </div>
        <div class="card__yaht-info">
          <div class="card__yaht-row" v-if="dateRange.start.dayWeek">
            <div class="card__yaht-title"><img src="/images/pick-up.svg">{{ $t('yacht.yacht_pick_up') }}</div>
            <div class="card__yaht-range">
              <div class="range-item__slider" id="rangeSlider9"></div>
              <div class="card__yaht-range-descr"><span></span><span></span></div>
            </div>
            <div class="card__yaht-date"><span>{{ dateRange.start.dayWeek }}, </span><span>{{ dateRange.start.month }} {{
                dateRange.start.year
              }}</span></div>
          </div>
          <div class="card__yaht-row" v-if="dateRange.end.dayWeek">
            <div class="card__yaht-title"><img src="/images/drop-off.svg">{{ $t('yacht.yacht_drop_off') }}</div>
            <div class="card__yaht-range">
              <div class="range-item">
                <div class="range-item__slider" id="rangeSlider10"></div>
              </div>
              <div class="card__yaht-range-descr"><span></span><span></span></div>
            </div>
            <div class="card__yaht-date"><span>{{ dateRange.end.dayWeek }}, </span><span>{{
                dateRange.end.month
              }} {{ dateRange.end.year }}</span></div>
          </div>
          <div class="card__yaht-text">
            {{ $t('yacht.the_charter_company_requires_the_yacht_to_be_returned_to_the_base_by') }}
            {{ getSelectedYacht.check_out_time && getSelectedYacht.check_out_time.slice(0, 5) }}
            {{ $t('yacht.in_the_evening_before_yacht_drop-off_for_inventory_checkout') }}
            {{ $t('yacht.mooring_in_the_marina_for_your_last_night_is_free') }}
          </div>
        </div>
        <div class="card__description">
          <div class="card__description-row">
            <div class="card__description-title">{{ $t('yacht.cancellation') }} / {{ $t('yacht.payment_policies') }}
            </div>
            <div class="card__description-text">
              {{ $t('yacht.cancellation_and_prepayment_policies_vary_according_to_your_selection') }}
              {{ $t('yacht.please_check_the_payment_conditions_when_selecting_the_price_above') }} <a
                @click="scrollToElement('availability')">{{ $t('actions.check_price') }}</a></div>
          </div>
          <div class="card__description-row">
            <div class="card__description-title">{{ $t('yacht.sailing_licence_required') }}</div>
            <div class="card__description-important"><img
                src="/images/check.svg">{{ $t('yacht.standard_sailing_licence') }}
            </div>
          </div>
          <div class="card__description-row">
            <div class="card__description-title">{{ $t('yacht.pets') }}</div>
            <div class="card__description-text">{{ $t('yacht.pets_are_not_permitted_on_this_boat') }}</div>
          </div>
          <div class="card__description-row">
            <div class="card__description-title">{{ $t('yacht.payment_methods_accepted_by_charter_company') }}</div>
            <div class="card__description-text">Euro €</div>
          </div>
          <div class="card__description-row">
            <div class="card__description-title">{{ $t('yacht.payment_methods_accepted_by_charter_company') }}</div>
            <div class="card__description-text">
              <p><span>{{ getSelectedYacht.base?.name }} </span>is situated nearby {{ getSelectedYacht.base?.city }},
                {{ getSelectedYacht.base?.country.name }}. It is located 10
                kilometres from the nearest airport and 3 kilometres from {{ getSelectedYacht.base?.city }} city centre.
              </p>
              <p>{{ getSelectedYacht.base?.name }} boasts a wide range of facilities for sailors, including an ATM,
                WiFi, a car
                park, a petrol station, a toilet, showers and a swimming pool.</p>
              <p>It also offers a restaurant and a supermarket.<br>All berths are connected to water and electricity.
              </p>
            </div>
          </div>
          <div class="card__description-row">
            <div class="card__description-title">{{ $t('interface.frequently_asked_questions') }}</div>
            <div class="card__faq">
              <div class="card__faq-item">
                <div class="card__faq-name">{{ $t('interface.what_is_a_transit_log') }}?</div>
                <div class="card__faq-text">To rent the boat {{ getSelectedYacht.model && getSelectedYacht.model.name }}
                  | {{ getSelectedYacht.name }}, you need a valid sailing license. You
                  can always verify the validation of your licence with us.
                </div>
              </div>
              <div class="card__faq-item">
                <div class="card__faq-name">What licences do I need to rent boat
                  {{ getSelectedYacht.model && getSelectedYacht.model.name }} | {{ getSelectedYacht.name }}?
                </div>
                <div class="card__faq-text">To rent the boat {{ getSelectedYacht.model && getSelectedYacht.model.name }}
                  | {{ getSelectedYacht.name }}, you need a valid sailing license. You
                  can always verify the validation of your licence with us.
                </div>
              </div>
              <div class="card__faq-item">
                <div class="card__faq-name">What payment methods are accepted for services and extras booked on the
                  spot?
                </div>
                <div class="card__faq-text">To rent the boat {{ getSelectedYacht.model && getSelectedYacht.model.name }}
                  | {{ getSelectedYacht.name }}, you need a valid sailing license. You
                  can always verify the validation of your licence with us.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        TODO: uncomment this when popular field will be added to yachts-->
        <!--        <div class="popular">-->
        <!--          <div class="card__heading">-->
        <!--            <h2 class="card__heading-name">Popular yachts close to your destination</h2>-->
        <!--            <div class="card__heading-subtitle">19 Oct 2024 - 26 Oct 2024</div>-->
        <!--          </div>-->
        <!--          <div class="popular__wrapper"><a class="product" href="#">-->
        <!--            <div class="product__left">-->
        <!--              <div class="product__photo"><img src="/images/product-1.png"></div>-->
        <!--              <div class="product__advantages">-->
        <!--                <div class="product__advantage"><img src="/images/advantage-1.svg"><span>Bathing platform</span></div>-->
        <!--                <div class="product__advantage"><img src="/images/advantage-2.svg"><span>BOutside GPS plotter</span>-->
        <!--                </div>-->
        <!--                <div class="product__advantage"><img src="/images/advantage-3.svg"><span>Outside speakers</span></div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="product__right">-->
        <!--              <div class="product__title">-->
        <!--                <div class="product__flag"><img src="/images/spain.png"></div>-->
        <!--                <div class="prodcut__name">Bavaria Cruiser 32 | Nenita</div>-->
        <!--                <div class="add-to-favorite">-->
        <!--                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                    <path-->
        <!--                        d="M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246"-->
        <!--                        stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"></path>-->
        <!--                  </svg>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="product__place">Palma de Mallorca, Real Club Nautico de Palma</div>-->
        <!--              <div class="product__stats">-->
        <!--                <div class="product__rating">4.7</div>-->
        <!--                <div class="product__reviews">3 Reviews</div>-->
        <!--                <div class="product__recently">Recently booked by a customer from <img src="/images/spain.png"></div>-->
        <!--              </div>-->
        <!--              <div class="product__info">-->
        <!--                <ul>-->
        <!--                  <li><span>Year</span><span>2010</span></li>-->
        <!--                  <li><span>People</span><span>5</span></li>-->
        <!--                  <li><span>Cabins</span><span>2</span></li>-->
        <!--                </ul>-->
        <!--                <ul>-->
        <!--                  <li><span>Yacht type</span><span>Sailing yacht</span></li>-->
        <!--                  <li><span>Length</span><span>9.99 m</span></li>-->
        <!--                  <li><span>Mainsail</span><span>Classic mainsail</span></li>-->
        <!--                </ul>-->
        <!--              </div>-->
        <!--              <div class="product__price">-->
        <!--                <div class="product__price-val">from <span>142 € </span>/ day</div>-->
        <!--                <div class="btn"><span>View yacht</span></div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </a><a class="product" href="#">-->
        <!--            <div class="product__left">-->
        <!--              <div class="product__photo"><img src="/images/product-1.png"></div>-->
        <!--              <div class="product__advantages">-->
        <!--                <div class="product__advantage"><img src="/images/advantage-1.svg"><span>Bathing platform</span></div>-->
        <!--                <div class="product__advantage"><img src="/images/advantage-2.svg"><span>BOutside GPS plotter</span>-->
        <!--                </div>-->
        <!--                <div class="product__advantage"><img src="/images/advantage-3.svg"><span>Outside speakers</span></div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="product__right">-->
        <!--              <div class="product__title">-->
        <!--                <div class="product__flag"><img src="/images/spain.png"></div>-->
        <!--                <div class="prodcut__name">Bavaria Cruiser 32 | Nenita</div>-->
        <!--                <div class="add-to-favorite">-->
        <!--                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                    <path-->
        <!--                        d="M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246"-->
        <!--                        stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"></path>-->
        <!--                  </svg>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="product__place">Palma de Mallorca, Real Club Nautico de Palma</div>-->
        <!--              <div class="product__stats">-->
        <!--                <div class="product__rating">4.7</div>-->
        <!--                <div class="product__reviews">3 Reviews</div>-->
        <!--                <div class="product__recently">Recently booked by a customer from <img src="/images/spain.png"></div>-->
        <!--              </div>-->
        <!--              <div class="product__info">-->
        <!--                <ul>-->
        <!--                  <li><span>Year</span><span>2010</span></li>-->
        <!--                  <li><span>People</span><span>5</span></li>-->
        <!--                  <li><span>Cabins</span><span>2</span></li>-->
        <!--                </ul>-->
        <!--                <ul>-->
        <!--                  <li><span>Yacht type</span><span>Sailing yacht</span></li>-->
        <!--                  <li><span>Length</span><span>9.99 m</span></li>-->
        <!--                  <li><span>Mainsail</span><span>Classic mainsail</span></li>-->
        <!--                </ul>-->
        <!--              </div>-->
        <!--              <div class="product__price">-->
        <!--                <div class="product__price-val">from <span>142 € </span>/ day</div>-->
        <!--                <div class="btn"><span>View yacht</span></div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </a></div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="overLay" v-if="showMap" @click="closeModal"></div>
    <BoatViewMap v-if="showMap" :yacht=getSelectedYacht @closeModal="closeModal"/>
    <div class="overLay" v-if="showRequestForm" @click="closeModal"></div>
    <RequestPrice v-if="showRequestForm" :yacht="getSelectedYacht" @closeModal="closeModal"/>
  </div>
</template>

<style scoped>
.overLay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  @media (max-width: 767px) {
    padding: 20px;
  }
}

.divider {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  height: 16px;
  margin: 0 10px;

}

img {
  max-width: 100%;
  height: 100%;
}

.icon-flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
}

.availability__slider {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.card__list-title img {
  height: 40px;
  width: 40px;

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}

.details-item {
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
}

.details-item__descr {
  font-size: 14px;
  text-align: right;
}

.sidebar__title {
  font-size: 16px;
}

.details__actions-buttons {
  flex-direction: column;
  width: 100%;
}

.details__actions-buttons-item a:last-child {
  text-decoration: none;
}

.details__actions-buttons-item a:last-child span {
  color: white;
}

.sidebar {
  height: 100%;
}

.availability__notice {
  color: #bf8d37;
}

.availability__notice {
  transition: color 0.8s;
}

.availability__slider {
  transition: background-color 0.8s;
}

.highlight {
  color: white !important;
}

.highlight-bg {
  background-color: #be8cec !important;
}

.sidebar__title span {
  color: #a8a8a8;;
  margin-left: 10px;
  margin-bottom: 15px !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 123%;
}

.excluded {
  padding: 15px 20px;
  margin-top: 15px;
}

.summary {
  margin-top: 15px;
}

.details-item__small {
  font-size: 12px;
  width: 100%;
}

.details-item__small-flex {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .card__yaht-title {
    margin-bottom: 0;
  }

  .card__yaht-date {
    text-align: right;
  }

  .card__yaht-range {
    display: none;
  }

  .page-nav__actions__desktop {
    display: none;
  }

  .page-nav__actions__mobile {
    display: flex;
  }
}

.page-nav__actions__mobile {
  display: none;
}

.card__description-text a {
  cursor: pointer;
}

#swiper-wrapper, .swiper-autoheight .swiper-slide {
  height: 100%;
}

.highlighted-slide {
  background-color: #f1ede1;
}

.btn span {
  text-wrap: nowrap;
}

.page-nav__link {
  display: inline-flex;
  align-items: center;
  transition: transform 0.5s ease-in-out;
}

.page-nav__link img {
  transition: transform 0.5s ease-in-out;
}

.page-nav__link.animated {
  transform: scale(1.1);
}

.page-nav__link img.rotated {
  transform: rotate(360deg);
}

.copy-pointer {
  cursor: copy;
}

#swiper-wrapper {
  height: max-content !important;
}
.swiper-slide img {
  max-height: 580px;
}
.swiper-slide:hover {
  background-color: #ececec;
}

@media (max-width: 768px) {
  .swiper-slide img {
    max-height: 246px;
  }
}
</style>