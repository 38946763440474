<script>
import {API_URL} from "@/constants";

export default {
  name: "BlogInnerView",
  data(){
    return {
      content: [],
      blogCategories: null,
    }
  },
  methods: {
    async fetchBlogContent() {
      const locale = this.$i18n.locale;
      const currentSlug = this.$route.path.split("/")[2];

      const url = `${API_URL}${locale}/${currentSlug}`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.content = data.content;
        if (data.title) {
          this.content.title = data.title;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchBlogListingPage() {
      const locale = this.$i18n.locale;
      const url = `${API_URL}/blogs/${locale}`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.blogCategories = data.blogCategories;

      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    await this.fetchBlogListingPage();
    await this.fetchBlogContent();

  }
}
</script>

<template>
  <div>
    <div class="blog-inner-image"></div>
    <div class="container">
      <div class="blog-inner">
        <div class="blog-inner__head">
          <a class="blog-inner__back" href="#"><img src="/images/prev.svg"></a>
          <div class="blog-inner__name">Travel Itineraries</div>
          <div class="blog-inner__date">9 December 2023 / 12:09</div>
        </div>
        <div class="blog-inner__wrapper">
          <div class="blog-inner__image"><img src="/images/blog-inner.png"></div>
          <h1 class="blog-inner__title">{{ content.title }}</h1>
          <div class="blog-inner__text">{{ content[1].content }}
          </div>
          <div class="blog-inner__gallery"><img src="/images/blog-inner-1.png"><img src="/images/blog-inner-2.png"></div>
          <h2 class="blog-inner__subtitle">Day 1: Kos - Kalimnos (20 nm)</h2>
          <div class="blog-inner__text">
            <p>{{ content[3].content }}</p>
            <p>{{ content[4].content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>