import Swiper from "swiper";
import $ from "jquery";
import {
  Navigation,
  Pagination,
  FreeMode,
  Virtual,
  Thumbs,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "select2";
// import "select2/dist/css/select2.css";
import "./daterangepicker.min";
import noUiSlider from "nouislider";
// import * as noUiSlider from "nouislider";
// import "nouislider/dist/nouislider.css";
import moment from "moment";

$(document).ready(function () {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();

  $(".language__current").click(function (e) {
    var languageList = $(".language__list");
    if (languageList.hasClass("active")) {
      languageList.removeClass("active");
    } else {
      languageList.addClass("active").css("display", "flex");
    }
  });

  $(document).mouseup(function (e) {
    var language = $(".language");
    if (!language.is(e.target) && language.has(e.target).length === 0) {
      $(".language__list").removeClass("active");
    }
  });

  $(".hamburger").click(function () {
    if ($(".hamburger").hasClass("active")) {
      $(".nav__link").css("opacity", "0");

      setTimeout(function () {
        $(".nav").removeClass("active");
      }, 150);

      $(this).removeClass("active");

      $("body").removeClass("hidden");

      return false;
    }

    $(this).addClass("active");

    $("body").addClass("hidden");

    if ($(".nav__link").css("opacity", "0")) {
      $(".nav").addClass("active");

      setTimeout(function () {
        $(".nav__link").css("opacity", "1");
      }, 150);
    } else {
      $(".nav__link").css("opacity", "0");

      setTimeout(function () {
        $(".nav").removeClass("active");
      }, 150);
    }
  });

  $(window).scroll(function () {
    var pageScroll = $(window).scrollTop();

    if (pageScroll > 20) {
      $(".header").addClass("header--scrolled");
    } else {
      $(".header").removeClass("header--scrolled");
    }
  });

  $(".reviews__stars").each(function () {
    let rate = $(this).data("rate");
    $(this)
      .find(".reviews__stars-fill")
      .each(function (index) {
        let star = $(this);
        if (rate > index) {
          if (rate < index + 1) {
            star.css("width", `${(rate - index) * 100}%`);
          } else {
            star.css("width", "100%");
          }
        } else {
          star.css("width", "0%");
        }
      });
  });

  var swiperPartners = new Swiper(".partners__slider", {
    modules: [Navigation],
    slidesPerView: 5,
    loop: true,
    navigation: {
      nextEl: ".partners__wrapper .swiper-button-next",
      prevEl: ".partners__wrapper .swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1025: {
        slidesPerView: 5,
      },
    },
  });

  $(document).ready(function () {
    $(".tab-content").hide();
    $(".tab-content:first").show();
    $(".tab-links li:first").addClass("active");

    $(".tab-links a").on("click", function (e) {
      var currentAttrValue = $(this).attr("href");

      $(".tab-content").hide();
      $(currentAttrValue).show();
      $(".tab-links li").removeClass("active");
      $(this).parent("li").addClass("active");

      if ($(currentAttrValue).is(":empty")) {
        $.ajax({
          type: "GET",
          url: "load_content.php", // Замените на нужный URL
          data: { tab: currentAttrValue },
          success: function (response) {
            $(currentAttrValue).html(response);
          },
        });
      }
      e.preventDefault();
    });
  });

  if ($(".grid__wrapper").length > 0) {
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      var wrapperTop = $(".grid__wrapper").offset().top;
      var windowHeight = $(window).height();

      if (scrollTop + windowHeight - 300 > wrapperTop) {
        $(".grid__item").each(function (index) {
          $(this)
              .delay(200 * index)
              .animate({ opacity: 1 }, 500);
        });
      }
    });
  }

  $(".add-to-favorite").click(function () {
    $(this).toggleClass("active");

    return false;
  });

  $(".catalog__categories").on("click", function () {
    $(".tab-links").slideToggle();
    $(this).toggleClass("active");

    return false;
  });

  $(document).on("click", function (e) {
    if (
      !$(e.target).closest(".tab-links").length &&
      !$(e.target).closest(".bloccatalog__categoriesk1").length
    ) {
      //hides tabs in search results on click so I removed
      // $(".tab-links").slideUp();
      $(".catalog__categories").removeClass("active");
    }
  });

  $(".catalog__filter").on("click", function () {
    console.log('check click')
    $(".sidebar").fadeIn().css("display", "flex");
    $("body").addClass("hidden");

    return false;
  });

  $(".sidebar__close").on("click", function () {
    $(".sidebar").fadeOut();

    $("body").removeClass("hidden");
  });

});
