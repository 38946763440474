import axios from 'axios';
import { openDB } from 'idb';

const URI_API = process.env.VUE_APP_API_URL;
const DB_NAME = 'anydaycharter-db';
const DB_VERSION = 1;
const STORE_NAME = 'destinations';

const state = {
    destinations: []
};

const mutations = {
    SET_DESTINATIONS(state, destinations) {
        state.destinations = destinations;
    }
};

const actions = {
    async fetchDestinations({ commit }) {
        try {
            // Open the IndexedDB database
            const db = await openDB(DB_NAME, DB_VERSION, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains(STORE_NAME)) {
                        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
                    }
                }
            });

            // Check if data is already cached in IndexedDB
            const cachedDestinations = await db.getAll(STORE_NAME);

            if (cachedDestinations && cachedDestinations.length > 0) {
                // If cached data exists, commit it to the state
                commit('SET_DESTINATIONS', cachedDestinations);
            } else {
                // Fetch data from API if not in cache
                const url = `${URI_API}/destinations`;
                const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
                const response = await axios.get(url, {
                    headers: {
                        'Accept': 'application/json',
                        Authorization: `Bearer ${tokenBearer}`
                    }
                });
                const destinations = response.data.data;

                // Clear existing data in IndexedDB and store the new data
                const tx = db.transaction(STORE_NAME, 'readwrite');
                await tx.objectStore(STORE_NAME).clear();
                await Promise.all(destinations.map(destination => tx.objectStore(STORE_NAME).put(destination)));
                await tx.done;

                // Commit the fetched data to the state
                commit('SET_DESTINATIONS', destinations);
            }
        } catch (error) {
            console.error('Error fetching destinations:', error);
        }
    }
};

const getters = {
    getDestinations: state => state.destinations
};

export default {
    state,
    mutations,
    actions,
    getters
};
