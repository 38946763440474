<script>

import {BASE} from "@/mixins";
import OfferCard from "@/components/partials/OfferCard.vue";
import OfferExpired from "@/views/details/OfferExpired.vue";
import LoadingIndicator from "@/components/globals/LoadingIndicator.vue";
import {API_URL} from "@/constants";

export default {
  name: "OfferView",
  components: {LoadingIndicator, OfferExpired, OfferCard},
  mixins:[BASE],
  data(){
    return {
      offerExpired: false,
      showLoadingIndicator: true,
      offer: {
        id: 0,
        uuid: "",
        yachts: [],
        country : {},
        end_date: '',
        start_date: '',

      },
    }
  },
  methods:{
    async fetchOfferContent(){
      const urlParams = new URL(window.location.href);
      const pathParts = urlParams.pathname.split('/');

      const uuid = decodeURIComponent(pathParts[pathParts.length - 1]);
      const url = `${API_URL}/offer/${uuid}`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        this.offer = await response.json();
        console.log(this.offer);
        const createdAtDate = new Date(this.offer.created_at);
        const currentDate = new Date();
        const timeDifference = currentDate - createdAtDate;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        if (daysDifference > 7) {
          this.offerExpired = true;
        }
        this.showLoadingIndicator = false;
      } catch (error) {
        console.error(error);
      }
    },


  },
  async beforeMount() {
    await this.fetchOfferContent();
  }
}
</script>

<template>
  <div>
    <LoadingIndicator v-if="showLoadingIndicator"/>
    <OfferExpired v-if="offerExpired"/>
    <div v-else class="container">
      <div class="mail-offer">
        <div class="mail-offer__title">{{ $t('interface.and_day_charter') }} - <span>{{ $t('interface.client_offer') }} #{{ offer.id }}</span></div>
        <div class="mail-offer__id">
          <div class="mail-offer__id-title">{{ $t('interface.proposal_id') }}: {{ offer.uuid }}</div>
          <div class="mail-offer__id-text">{{ $t('interface.we_can_offer_you_proposals_listed_below') }} <br>
            {{ $t('interface.if_you_want_to_make_an_option_or_booking_please_replay_on_this_mail_with_proposal_ID_you_like') }}</div>
        </div>
      </div>

      <OfferCard v-for="yacht in offer.yachts" :yacht="yacht" :key="yacht.yacht.slug" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .mail-offer {
    padding-top: 150px;
  }

  .mail-offer__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 124%;
    color:#21084d;
    margin-bottom: 34px;

    span {
      font-weight: 400;
      font-size: 36px;
      color: #bf8d37;
    }
  }

  .mail-offer__id {
    background: #21084d;
    border-radius: 10px;
    display: flex;
    widows: 100%;
    margin-bottom: 30px;

    @media(max-width: 767px) {
      flex-direction: column;
    }
  }

  .mail-offer__id-title {
    padding: 43px 30px;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #ffffff;
    border-right: 1px solid rgba(255, 255, 255, 0.3);

    @media(max-width: 767px) {
      border-right: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding: 20px;
    }
  }

  .mail-offer__id-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 147%;
    color: #ffffff;
    padding: 33px 30px;

    @media(max-width: 767px) {
      padding: 20px;
    }
  }

  .mail-offer__wrapper {
    display: flex;
    align-items: flex-start;
    gap: 17px;

    @media(max-width: 1024px) {
      flex-direction: column;
    }
  }


</style>