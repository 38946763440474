<script>
import BreadCrumbs from "@/components/partials/BreadCrumbs.vue";

export default {
  name: "OfferExpired",
  data(){
    return {
      contacts: {
        name:"",
        email:""
      }
    }
  },
  methods: {
    async fetchContacts() {
      const URI_API = process.env.VUE_APP_API_URL;
      const url =  `${URI_API}/settings`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        this.contacts = {
          phone: data.phone || this.contacts.phone,
          email: data.email || this.contacts.email,
        };
      } catch (error) {
        console.error(error);
      }
    }
  },
  async mounted(){
    await this.fetchContacts()
  }

}
</script>

<template>
  <div class="container">

    <div class="details__modal details__success">
      <img src="/images/ThankYouImage.png" alt="ThankYouImage">
     <div class="details__success-wrapper">
       <div class="details__success-top-wrapper">
         <div class="details__success-title">{{ $t('interface.congratulations') }}</div>
         <div class="details-item details-item--success">
           <div class="details-item__name details-item__name--success">{{ $t('interface.your_personalized_offer_was_updated') }}</div>
         </div>
       </div>
       <div class="manager">
         <div class="manager__title">{{ $t('interface.contact_us') }}</div>
         <div class="manager__info">
           <div class="manager__photo"><img src="/images/manager.png"></div>
           <div class="manager__descr"><span>{{ $t('interface.contact_manager') }}</span><span>{{ $t('interface.your_sailing_assistant') }}</span></div>
         </div>
         <div class="manager__contacts">
           <a :href="`mailto:${contacts.email}`">
             <img src="/images/mail.svg" alt="Email logo">
             <span>{{ contacts.email }}</span>
           </a>
           <a :href="`tel:${contacts.phone}`">
             <img src="/images/phone.svg" alt="Phone logo">
             <span>{{ contacts.phone }}</span>
           </a>
         </div>
       </div>
       <div class="details__success-low-wrapper">
         <div class="details-item__descr details-item__descr--success">{{ $t('interface.or_you_can_continue_your_search_by_your_own') }}</div>
         <a class="btn btn--bordered" :href="`/${$i18n.locale}`">{{ $t('actions.i_will') }}</a>
       </div>
     </div>
    </div>
  </div>
</template>

<style scoped>
.details__success-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: #21084d;
}
.details__success-top-wrapper{
  padding: 50px 50px 30px;
}
.details__success-low-wrapper {
  padding: 30px 50px;
}


.details__success-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 42px;
}

.details__modal {
  width: 953px;
  height: 675px;
  top: 15%;
  background-color: #ffffff;
  left: 30%;
  margin: auto;
  min-height: 200px;
  box-shadow: 0 10px 15px -2px rgba(0, 0, 0, .3);
  border-radius: 10px;
  z-index: 999;
}

.details__success {
  margin: 175px auto;
  display: flex;
  align-items: center;
  padding: 0 50px 30px;
  gap: 20px;
}
@media (max-width: 767px) {
  .details__success {
    width: 100% !important;
  }

}
.details-item__name--success {

  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: black;
}

.details-item__code--success {
  font-size: 30px;
  font-weight: 600;
  line-height: 28px;
  color: #BF8D37;
}

.details-item__code--success div {
  margin-top: 15px;
  color: #21084d;
}

.details-item__descr--success {
  margin-bottom: 15px;
}
.details-item__code--success {
  margin-top: 5px;
}
.details-item__name--success {
  margin-top: 30px;
}
.btn--bordered {
  width: 100%;
}
@media (max-width: 767px) {
  .details__success {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin: 110px auto;
  }
  .details__success img {
    border-radius: 10px;
  }
  .details__success-title {
    font-size: 30px;
  }
  .details__success-top-wrapper {
    padding: 40px 20px;
  }
  .details-item__name--success {
    margin-top: 10px;
    font-size: 16px;
  }
  .details-item__code--success {
    font-size: 22px;
  }
}

</style>