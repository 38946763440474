<script>
import {mapActions, mapGetters} from "vuex";
import NoUiSliderHours from "@/components/partials/NoUiSliderHours.vue";
import NoUiSliderHoursRange from "@/components/partials/NoUISliderHoursRange.vue";
import BreadCrumbs from "@/components/partials/BreadCrumbs.vue";
import {
  calculateDaysDifference,
  calculateProductPrice,
  checkYachtDetails,
  formatDateInDetails,
  newDate, scrollToElement, toggleFavorite
} from "@/utilities/service";
import intlTelInput from "intl-tel-input";
import LoadingIndicator from "@/components/globals/LoadingIndicator.vue";
import $ from "jquery";

export default {
  name: "DetailsView",
  components: {LoadingIndicator, BreadCrumbs, NoUiSliderHoursRange, NoUiSliderHours},
  data() {
    return {
      showResultData: false,
      showSuccessModal: false,
      showLoadingIndicator: true,
      isFavorite: false,
      selectedPeriod: null,
      includedExtrasPrice: 0,
      excludedExtrasPrice: 0,
      flexibleCancellation: false,
      phonemaskInputExists: false,
      productId: null,
      orderCode: null,
      customer: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        specialRequests: "",
        selectedExtras: [],
      }
    }
  },
  methods: {
    checkYachtDetails,
    formatDateInDetails,
    newDate,
    calculateDaysDifference,
    toggleFavorite,
    calculateProductPrice,
    scrollToElement,
    ...mapActions(["fetchSelectedYacht"]),
    getInitialCustomerState() {
      return {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        specialRequests: "",
        selectedExtras: [],
      };
    },
    resetCustomer() {
      this.customer = this.getInitialCustomerState();
    },
    updateExtrasWithChecked(extras) {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('fc')) {
        this.flexibleCancellation = true;
      }

      extras.forEach(extra => {
        extra.checked = urlParams.has(extra.id.toString());
        if (extra.checked) {
          if (extra.payable_in_base) {
            this.excludedExtrasPrice += Number(this.calculateProductPrice(extra));
          } else {
            this.includedExtrasPrice += Number(this.calculateProductPrice(extra));
          }
        }
      });
    },

    proceedToFinalDetails() {
      if (!this.customer.firstName || !this.customer.lastName || !this.customer.phoneNumber || !this.customer.email) {
        this.scrollToElement('product')
        this.$notify({
          title: 'Warning',
          text: 'Please fill all required data',
          type: 'warn'
        });
        return;
      }

      const yachtSlug = this.getSelectedYacht.slug;
      const locale = this.$i18n.locale;
      const currentUrl = new URL(window.location.href);
      const params = new URLSearchParams(currentUrl.search);

      let newUrl = `/${locale}/detailsfinal/${yachtSlug}`;
      localStorage.setItem('customer', JSON.stringify(this.customer));
      params.forEach((value, key) => {
        newUrl += (newUrl.includes('?') ? '&' : '?') + `${key}=${value}`;
      });
      window.location.href = newUrl;
    },
    checkIfFavorite() {
      const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
      this.isFavorite = favorites.includes(this.getSelectedYacht.slug);
    },
    getUserInitialInfo() {
      if (this.getUser.authentificated) {
        this.customer = {
          firstName: this.getUser.firstName,
          lastName: this.getUser.lastName,
          email: this.getUser.email,
          phoneNumber: this.getUser.phone
        }
      } else {
        const customerData = localStorage.getItem('customer');
        if (customerData) {
          this.customer = JSON.parse(customerData);
        }
      }
    },
    showSidebar() {
      $(".sidebar").fadeIn().css("display", "flex");
      $("body").addClass("hidden");
    },
    hideSidebar() {
      $(".sidebar").fadeOut();
      $("body").removeClass("hidden");
    }
  },

  computed: {
    ...mapGetters(["getSelectedYacht", "getUser"]),
    dateRange() {
      const params = new URLSearchParams(window.location.search);
      const checkIn = params.get('date_start');
      const checkOut = params.get('date_end');

      if (checkIn && checkOut) {
        const startTimestamp = new Date(checkIn).getTime();
        const endTimestamp = new Date(checkOut).getTime();

        return {
          start: formatDateInDetails(startTimestamp),
          end: formatDateInDetails(endTimestamp),
          daysDifference: calculateDaysDifference(startTimestamp, endTimestamp)
        };
      }
      return {
        start: "",
        end: "",
        daysDifference: ""
      };
    },
    initialPrice() {
      let result = 0;
      if (this.selectedPeriod) {
        result = this.selectedPeriod.price;
      }
      return result;
    },
    summaryPrice() {
      const initialPrice = Number(this.initialPrice);
      const includedExtrasPrice = Number(this.includedExtrasPrice);
      if (this.flexibleCancellation) {
        return Math.round(1.08 * (initialPrice + includedExtrasPrice));
      }
      return Math.round(initialPrice + includedExtrasPrice);
    },
    obligatoryPayableInBase() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if (!products.length) return [];

      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && prod.payable_in_base);
    },
    obligatoryPayableImmediately() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;

      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && !prod.payable_in_base);
    },
    optionalPayableInBase() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;

      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && prod.payable_in_base && prod.checked);
    },
    optionalPayableImmediately() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;

      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && !prod.payable_in_base && prod.checked);
    },
    getFlagClass() {
      if (this.getSelectedYacht.base && this.getSelectedYacht.base.country.short) {
        return `fi icon-flag fi-${this.getSelectedYacht.base.country.short.toLowerCase()}`;
      }
      return '';
    }

  },
  async mounted() {


    await this.fetchSelectedYacht().then((res) => {
      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if (products.length) {
        if (products.length === 1) {
          selectedProduct = products[0];
        } else {
          selectedProduct = products.find(product => product.default_product) || products[0];
        }
        this.productId = selectedProduct.id;
        this.updateExtrasWithChecked(selectedProduct.extras);
      }
      this.showLoadingIndicator = false;
    });
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);


    params.forEach((value, key) => {
      if (value === 'true' && !isNaN(key)) {
        this.customer.selectedExtras.push(key);
      }
    });
    const intervalId = setInterval(() => {
      const input = document.querySelector(".phonemask");
      if (input) {
        this.phonemaskInputExists = true;
        clearInterval(intervalId);
      }
    }, 100);
    this.getUserInitialInfo();
    this.checkIfFavorite();
  },
  watch: {
    getSelectedYacht(newVal) {
      if (newVal) {
        const params = new URLSearchParams(window.location.search);
        const checkIn = params.get('date_start');
        this.selectedPeriod = newVal.period_price.find(item => item.date_from.startsWith(checkIn));
      }
    },
    phonemaskInputExists(newVal) {
      if (newVal) {
        const input = document.querySelector(".phonemask");
        intlTelInput(input, {
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.0.4/js/utils.js",
          initialCountry: "cy",
          nationalMode: true,
          showSelectedDialCode: true,
        });
      }
    }
  },

}
</script>

<template>
  <LoadingIndicator v-if="showLoadingIndicator"/>
  <template v-else>
    <div class="container">
      <div class="page-nav">
        <BreadCrumbs :base="getSelectedYacht.base?.city"/>
        <!--      <div class="page-nav__actions">-->
        <!--        <a class="page-nav__link" href="#">-->
        <!--          <span>Map</span>-->
        <!--          <img src="/images/map.svg">-->
        <!--        </a>-->
        <!--      </div>-->
      </div>
      <div class="details-progress">
        <span class="details-progress__item details-progress__item--done"> {{
            getSelectedYacht.model && getSelectedYacht.model?.name
          }} | {{ getSelectedYacht.name }}</span>
        <span class="details-progress__item details-progress__item--current">{{ $t('yacht.enter_your_details') }}</span><span
          class="details-progress__item details-progress__item--empty">{{ $t('yacht.final_details') }}</span>
        <div style="width: 52%"></div>
      </div>
      <div class="catalog__mobile-nav">
        <a class="page-nav__link catalog__filter" @click="showSidebar"><span>{{ $t('yacht.info') }}</span><img
          src="/images/filter.svg"></a>
      </div>
      <div class="details-page">
        <div class="sidebar">
          <div class="sidebar__close" @click="hideSidebar"><img src="/images/close.svg"></div>
          <div class="booking">
            <div class="sidebar__title">{{ $t('yacht.your_booking_details') }}</div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_pick_up') }}</div>
              <div class="details-item__descr">{{ dateRange.start.dayWeek }} {{ dateRange.start.month }}
                {{ dateRange.start.year }}
              </div>

            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_drop_off') }}</div>
              <div class="details-item__descr">{{ dateRange.end.dayWeek }} {{ dateRange.end.month }}
                {{ dateRange.end.year }}
              </div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.total_length_of_rental') }}</div>
              <div class="details-item__descr">{{ dateRange.daysDifference }} {{ $t('interface.days') }}</div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_pick-up_and_drop-off_address') }}</div>
              <div class="details-item__descr">{{ getSelectedYacht.base?.name }}</div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.charter_company') }}</div>
              <div class="details-item__descr">{{ getSelectedYacht.company?.name }}</div>
            </div>
          </div>
          <div class="summary">
            <div class="sidebar__title">{{ $t('yacht.price_summary') }}</div>
            <div class="details-item " v-if="flexibleCancellation">
              <div class="details-item__name details-item__small details-item__name details-item__small-flex">
                <span>{{ $t('yacht.flexible_cancellation') }}</span> <span>{{
                  Math.round(initialPrice * 0.08)
                }} {{ getSelectedYacht.currency }}</span></div>
            </div>
            <div class="summary__rental"><span>{{ $t('yacht.yacht_rental') }}</span><span>{{
                initialPrice
              }} {{ getSelectedYacht.currency }}</span></div>
            <div class="summary__total"><span>{{ $t('yacht.total_rental_charge') }}</span><span>{{
                summaryPrice
              }} {{ getSelectedYacht.currency }}</span><span>{{ $t('yacht.available') }}</span></div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.price') }} {{ $t('interface.for') }}
                {{ dateRange.daysDifference }} {{ $t('interface.days') }}
              </div>
              <div class="details-item__descr">{{ $t('yacht.includes_taxes_and_charges') }}</div>
              <div class="note">{{ $t('interface.bingo') }}
                {{ $t('interface.you_just_got_the_best_price_available_online') }}
              </div>
            </div>
            <a class="promocode" href="#"><img src="/images/promocode.svg"
                                               alt="Promo code icon">{{ $t('yacht.do_you_have_a_promo_code') }}</a>
          </div>
          <div class="due-now"><span>{{ $t('yacht.due_now') }}</span><span>{{
              summaryPrice
            }} {{ getSelectedYacht.currency }}</span></div>
          <div class="limited">
            <img src="/images/alarm.svg" alt="Alarm icon">
            <span>{{ $t('yacht.limited_offer_in') }} {{
                getSelectedYacht.country?.name
              }}. {{ $t('yacht.dont_miss_out_to_secure_the_best_price') }} </span>
          </div>
          <div class="schedule">
            <div class="sidebar__title">{{ $t('yacht.payment_schedule') }}</div>
            <div class="schedule__wrapper"><img src="/images/check.svg" alt="Check">
              <div class="schedule__info">
                <div class="schedule__price">{{ summaryPrice }} €</div>
                <div class="schedule__date">{{ $t('yacht.full_payment_by') }} 2 Apr 2024</div>
              </div>
            </div>
          </div>
          <div class="included">
            <div class="included__title">{{ $t('yacht.your_booking_includes') }}</div>
            <div class="included__item"><span>{{ $t('yacht.included') }}</span><span>Free Wi-Fi</span></div>
          </div>
          <div class="excluded">
            <div class="sidebar__title">{{ $t('yacht.excluded_charges') }}</div>
            <div class="details-item" v-for="product in obligatoryPayableInBase" :key="product.id">
              <div class="details-item__name details-item__small">{{ product.name }}</div>
              <div class="details-item__descr details-item__small">{{ product.price }} {{ product.currency }}
                {{ product.unit }} {{ $t('yacht.mandatory').toLowerCase() }}
              </div>
            </div>
          </div>
          <!--          <div class="cancel">-->
          <!--            <div class="sidebar__title">{{ $t('yacht.how_much_will_it_cost_to_cancel') }}</div>-->
          <!--            <div class="cancel__wrapper">-->
          <!--              <div class="cancel__item">2 Apr 2024</div>-->
          <!--              <span class="cancel__descr">{{ $t('yacht.the_cancellation_fee_is') }} 100% {{ $t('interface.after') }} 2 Apr 2024</span>-->
          <!--              <div class="cancel__item">{{ dateRange.start.dayWeek }} {{ dateRange.start.month }} {{ dateRange.start.year }}-->
          <!--                ({{ $t('yacht.yacht_pick_up') }})-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="details">
          <div class="details__wrapper"><a class="product" @click="checkYachtDetails(true)">
            <div class="product__left">
              <div class="product__photo"><img v-if="getSelectedYacht.mainImageUrls"
                                               :src="getSelectedYacht.mainImageUrls[400] || getSelectedYacht.mainImageUrls[800]"
                                               :alt="getSelectedYacht.model + ' | ' + getSelectedYacht.name"></div>
            </div>
            <div class="product__right">
              <div class="product__title">
                <span :class="getFlagClass"></span>
                <div class="prodcut__name"> {{ getSelectedYacht.model && getSelectedYacht.model?.name }} |
                  {{ getSelectedYacht.name }}
                </div>
                <div class="add-to-favorite" @click.stop="toggleFavorite(getSelectedYacht)">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        :d="isFavorite ? 'M24.9707 6.70088C22.7028 4.43304 19.0274 4.43304 16.6813 6.70088L15.0391 8.34305L13.3969 6.70088C11.0512 4.43304 7.37571 4.43304 5.10787 6.70088C2.84 8.96874 2.84 12.6442 5.10787 14.9121L15.0391 25L24.9707 14.9121C27.2385 12.6442 27.2385 8.96874 24.9707 6.70088Z' : 'M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246'"
                        :fill="isFavorite ? '#BF8D37' : 'none'"
                        stroke="#BF8D37"
                        stroke-width="3"
                        stroke-miterlimit="22.9256"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="product__place">{{ getSelectedYacht.base?.city }}, {{ getSelectedYacht.base?.name }}</div>
              <!--            <div class="product__stats">-->
              <!--              <div class="product__rating">4.7</div>-->
              <!--              <div class="product__reviews">3 Reviews</div>-->
              <!--              <div class="product__recently">Recently booked by a customer from <img src="/images/spain.png"></div>-->
              <!--            </div>-->
              <div class="product__info">
                <ul>
                  <li><span>{{ $t('yacht.year') }}</span><span>{{ getSelectedYacht.year }}</span></li>
                  <li><span>{{ $t('yacht.people') }}</span><span>{{ getSelectedYacht.max_people }}</span></li>
                  <li><span>{{ $t('yacht.cabins') }}</span><span>{{ getSelectedYacht.cabins }}</span></li>
                </ul>
                <ul>
                  <li><span>{{ $t('yacht.yacht_type') }}</span><span>{{ getSelectedYacht.type?.name }}</span></li>
                  <li><span>{{ $t('yacht.length') }}</span><span>{{ getSelectedYacht.length }} m</span></li>
                  <li><span>{{ $t('yacht.mainsail') }}</span><span>Classic mainsail</span></li>
                </ul>
              </div>
            </div>
          </a>
            <form class="details__form">
              <h2 class="card__heading-name">{{ $t('yacht.enter_your_details') }}</h2>
              <div class="form-group">
                <label>
                  <img src="/images/required.svg">{{ $t('user.first_name') }}</label>
                <input type="text" v-model="customer.firstName" required>
              </div>
              <div class="form-group">
                <label><img src="/images/required.svg">{{ $t('user.last_name') }}</label>
                <input type="text" v-model="customer.lastName" required>
              </div>
              <div class="form-group">
                <label><img src="/images/required.svg">{{ $t('user.email') }}</label>
                <input type="email" v-model="customer.email" required>
              </div>
              <div class="form-group">
                <label><img src="/images/required.svg">{{ $t('user.phone') }}</label>
                <input class="phonemask" type="tel" v-model="customer.phoneNumber" required>
              </div>
              <div class="details__form-descr">
                <span>{{ $t('user.we_will_send_you_an_email_confirmation_to_this_address') }}</span>
                <div class="registration__descr"><img src="/images/required.svg">{{ $t('interface.required_field') }}
                </div>
              </div>
            </form>
            <div class="details__request">
              <div class="details__request-title">{{ $t('user.special_requests') }}</div>
              <div class="details__request-subtitle">
                {{ $t('user.you_can_always_make_a_special_request_after_your_booking_is_completed') }}
              </div>
              <div class="form-group">
                <textarea v-model="customer.specialRequests"></textarea>
              </div>
              <div class="details__actions">
                <div class="details__actions-title">{{ $t('user.dont_worry_you_wont_be_charged_now') }}</div>
                <div class="details__actions-buttons">
                  <!--                <div class="details__actions-buttons-item">-->
                  <!--                  <a class="btn btn&#45;&#45;bordered" href="javascript:void(0)" @click="showResultData=true">{{ $t('user.request_a_quote') }}</a>-->
                  <!--                  <span>-->
                  <!--                    <b>{{ $t('user.not_sure_yet') }} </b>-->
                  <!--                    {{ $t('user.discover_your_personal_quote') }}-->
                  <!--                  </span>-->
                  <!--                </div>-->
                  <div class="details__actions-buttons-item">
                    <a class="btn" @click="proceedToFinalDetails">
                      <span>{{ $t('actions.next') }}: {{ $t('yacht.final_details') }} </span>
                    </a>
                    <a href="#">{{ $t('interface.what_are_my_booking_conditions') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="details__advantages">
            <div class="details__advantages-item">
              <div class="details__advantages-icon"><img src="/images/advantages-1.svg"></div>
              <div class="details__advantages-name">{{ $t('interface.always_the_best_price') }}</div>
              <div class="details__advantages-text">{{ $t('interface.no_excuses') }} {{
                  $t('interface.no_hidden_fees')
                }}
              </div>
            </div>
            <div class="details__advantages-item">
              <div class="details__advantages-icon"><img src="/images/advantages-2.svg"></div>
              <div class="details__advantages-name">{{ $t('interface.weve_got_your_back') }}</div>
              <div class="details__advantages-text">{{
                  $t('interface.we_take_take_care_of_any_problem_you_might_have')
                }} {{ $t('interface.immediately_and_without_questions') }}
              </div>
            </div>
            <div class="details__advantages-item">
              <div class="details__advantages-icon"><img src="/images/advantages-3.svg"></div>
              <div class="details__advantages-name">{{ $t('interface.sail_more_worry_less') }}</div>
              <div class="details__advantages-text">100% {{ $t('interface.verified_partners_and_boats') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overLay" v-if="showResultData" @click="showResultData=false"></div>
    <div v-if="showResultData" class="details__modal">
      <div class="details__modal-title">
        {{ $t('yacht.your_booking_details') }}
      </div>
      <div class="details__modal-customer">
        <div class="details__modal-subtitle">
          {{ $t('user.customer_data') }}
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('user.name') }}</div>
          <div class="details-item__descr">{{ customer.firstName }} {{ customer.lastName }}</div>
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('user.email') }}</div>
          <div class="details-item__descr">{{ customer.email }}</div>
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('user.phone') }}</div>
          <div class="details-item__descr">{{ customer.phoneNumber }}</div>
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('user.special_requests') }}</div>
          <div class="details-item__descr">{{ customer.specialRequests }}</div>
        </div>
      </div>
      <div class="details__modal-boat">
        <div class="details__modal-subtitle">
          {{ $t('yacht.boat_data') }}
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('yacht.yacht_pick_up') }}</div>
          <div class="details-item__descr">{{ dateRange.start.dayWeek }} {{ dateRange.start.month }}
            {{ dateRange.start.year }}
          </div>

        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('yacht.yacht_drop_off') }}</div>
          <div class="details-item__descr">{{ dateRange.end.dayWeek }} {{ dateRange.end.month }} {{
              dateRange.end.year
            }}
          </div>
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('yacht.total_length_of_rental') }}</div>
          <div class="details-item__descr">{{ dateRange.daysDifference }} {{ $t('interface.days') }}</div>
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('yacht.yacht_pick-up_and_drop-off_address') }}</div>
          <div class="details-item__descr">{{ getSelectedYacht.base?.name }}</div>
        </div>
        <div class="details-item">
          <div class="details-item__name">{{ $t('yacht.charter_company') }}</div>
          <div class="details-item__descr">{{ getSelectedYacht.company?.name }}</div>
        </div>
      </div>

      <div class="details__modal-reservation-details">
        <div class="sidebar__title">{{ $t('yacht.excluded_charges') }}</div>
        <div class="details-item " v-for="product in obligatoryPayableInBase" :key="product.id">
          <div class="details-item__name details-item__small">{{ product.name }}</div>
          <div class="details-item__descr details-item__small">{{ product.price }} {{ product.currency }}
            {{ product.unit }} {{ $t('yacht.mandatory').toLowerCase() }}
          </div>
        </div>
        <div class="details-item " v-for="product in optionalPayableInBase" :key="product.id">
          <div class="details-item__name details-item__small">{{ product.name }}</div>
          <div class="details-item__descr details-item__small">{{ product.price }} {{ product.currency }}
            {{ product.unit }} {{ $t('yacht.mandatory').toLowerCase() }}
          </div>
        </div>

      </div>
      <div class="details__modal-reservation-details">
        <div class="sidebar__title"
             v-if="flexibleCancellation || optionalPayableImmediately.length || obligatoryPayableImmediately.length">
          {{ $t('yacht.included_charges') }}
        </div>

        <div class="details-item " v-if="flexibleCancellation">
          <div class="details-item__name details-item__small">{{ $t('yacht.flexible_cancellation') }}</div>
          <div class="details-item__descr details-item__small">{{ initialPrice * 0.08 }} {{ getSelectedYacht.currency }}
            {{ $t('yacht.per_rental') }} {{ $t('yacht.optional').toLowerCase() }}
          </div>
        </div>
        <div class="details-item " v-for="product in optionalPayableImmediately" :key="product.id">
          <div class="details-item__name details-item__small">{{ product.name }}</div>
          <div class="details-item__descr details-item__small">{{ product.price }} {{ product.currency }}
            {{ product.unit }} {{ $t('yacht.optional').toLowerCase() }}
          </div>
        </div>
        <div class="details-item " v-for="product in obligatoryPayableImmediately" :key="product.id">
          <div class="details-item__name details-item__small">{{ product.name }}</div>
          <div class="details-item__descr details-item__small">{{ product.price }} {{ product.currency }}
            {{ product.unit }} {{ $t('yacht.optional').toLowerCase() }}
          </div>
        </div>

      </div>
      <div class="summary">
        <div class="sidebar__title">{{ $t('yacht.price_summary') }}</div>
        <div class="summary__rental"><span>{{ $t('yacht.total_rental_charge') }}</span><span>{{
            summaryPrice
          }} {{ getSelectedYacht.currency }}</span></div>
      </div>

      <div class="details__actions">
        <div class="details__actions-buttons">
          <div class="details__actions-buttons-item">
            <a class="btn btn--bordered" @click="showResultData=false">{{ $t('actions.close') }}</a>
          </div>
          <div class="details__actions-buttons-item">
            <a class="btn" @click="sendRequest"><span>{{ $t('actions.confirm') }}</span></a>
          </div>
        </div>
      </div>
    </div>

  </template>
</template>

<style scoped>
.details__modal {
  width: 468px;
  position: absolute;
  top: 15%;
  background-color: #ffffff;
  left: 30%;
  margin: auto;
  min-height: 200px;
  box-shadow: 0 10px 15px -2px rgba(0, 0, 0, .3);
  border-radius: 10px;
  z-index: 999;
}

.details__modal--success {
  margin: 15% auto;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px
}

.details-item--success,
.details-item__name--success,
.details-item__descr--success {
  margin-bottom: 25px;
}

.details__actions-buttons-item a:last-child {
  text-decoration: none;
}

.details__actions-buttons-item a:last-child span {
  color: white;
}

.noUi-handle {
  background-color: #6c6c6c !important;
}

.noUi-handle.noUi-handle-upper {
  background-color: #6c6c6c !important;
}

.details__modal-title {
  padding: 32px 0;
  font-family: "Adelle Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: #BF8D37;
  border-bottom: 1px solid black;
}

.details__modal-customer, .details__modal-boat {
  padding: 40px 50px;
  border-bottom: 1px solid black;
}

.details__modal-reservation-details {
  padding: 15px 50px;
}

.details__modal-subtitle {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #21084D;
}

.overLay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.details__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 20px;
}

.icon-flag {
  margin-right: 5px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
}

.details-item__small {
  font-size: 12px;
}

.details-item__small-flex {
  display: flex;
  justify-content: space-between;
}

.iti__tel-input::placeholder {
  color: #d3cfcf !important;
}
</style>