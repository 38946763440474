 const popularDestinations = [
    {
        "id": 218,
        "name": "Italy",
        "slug": "italy",
        "icon": "IT",
        "type": "country"
    },
     {
         "id": 101,
         "name": "Croatia",
         "slug": "croatia",
         "icon": "HR",
         "type": "country"
     },
     {
         "id": 151,
         "name": "Turkey",
         "slug": "turkey",
         "icon": "TR",
         "type": "country"
     },
     {
         "id": 24,
         "name": "Greece",
         "slug": "greece",
         "icon": "GR",
         "type": "country"
     },
     {
         "id": 182,
         "name": "British Virgin Islands",
         "slug": "british-virgin-islands",
         "icon": "VG",
         "type": "country"
     },
     {
         "id": 2833,
         "name": "Palma de Mallorca",
         "slug": "city_palma-de-mallorca",
         "icon": "ES",
         "type": "city"
     },
     {
         "id": 2837,
         "name": "Split",
         "slug": "city_split",
         "icon": "HR",
         "type": "city"
     },
     {
         "id": 3579,
         "name": "Athens",
         "slug": "city_athens",
         "icon": "GR",
         "type": "city"
     }
]
const API_URL = process.env.VUE_APP_API_URL;
export { popularDestinations, API_URL } ;