import axios from 'axios';

const URI_API = process.env.VUE_APP_API_URL;

const state = {
    languages: [],
    currentLanguage: ""
};

const mutations = {
    SET_LANGUAGES(state, languages) {
        state.languages = languages;
    },
    SET_CURRENT_LANGUAGE(state, language) {
        state.currentLanguage = language;
    }
};

const actions = {
    async fetchLanguages({ commit }) {
        try {
            const url = `${URI_API}/languages`;
            const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenBearer}`
                }
            });
            const languages = response.data;
            commit('SET_LANGUAGES', languages);
        } catch (error) {
            console.error('Error fetching available locales:', error);
        }
    },
    setCurrentLanguage({ commit }, language) {
        commit('SET_CURRENT_LANGUAGE', language);
    }
};

const getters = {
    getLanguages: state => state.languages,
    getCurrentLanguage: state => state.currentLanguage,
};

export default {
    state,
    mutations,
    actions,
    getters
};
