<template>
  <div v-if="!pageData.mainContent">
    <LoadingIndicator />
  </div>
  <div v-else>
    <div class="about-info" v-if="pageData && pageData.mainContent">
      <div class="container">
        <div class="page-head">
          <h1 class="page-head__title">{{ pageData.title }}</h1>
        </div>
        <div class="about-info__wrapper">
          <div class="about-info__item">
            <div class="about-info__title">{{ pageData.mainContent?.left_header_title }}</div>
            <div class="about-info__text">{{ pageData.mainContent?.left_header_text }}</div>
          </div>
          <div class="about-info__item">
            <div class="about-info__title">{{ pageData.mainContent?.right_header_title }}</div>
            <div class="about-info__text">{{ pageData.mainContent?.right_header_text }}</div>
          </div>
        </div>
        <div class="about-info__advantages">
          <div class="about-info__advantages-item">
            <div class="about-info__advantages-value">8500</div>
            <div class="about-info__advantages-name">{{ pageData.mainContent?.advantages_first_title }}</div>
            <div class="about-info__advantages-text">{{ pageData.mainContent?.advantages_first_text }}</div>
          </div>
          <div class="about-info__advantages-item">
            <div class="about-info__advantages-value">130</div>
            <div class="about-info__advantages-name">{{ pageData.mainContent?.advantages_second_title }}</div>
            <div class="about-info__advantages-text">{{ pageData.mainContent?.advantages_second_text }}</div>
          </div>
          <div class="about-info__advantages-item">
            <div class="about-info__advantages-value"><img src="/images/about-advantages-1.svg" alt="Support"></div>
            <div class="about-info__advantages-name">{{ pageData.mainContent?.advantages_third_title }}</div>
            <div class="about-info__advantages-text">{{ pageData.mainContent?.advantages_third_text }}</div>
          </div>
          <div class="about-info__advantages-item">
            <div class="about-info__advantages-value"><img src="/images/about-advantages-2.svg" alt="Best offers"></div>
            <div class="about-info__advantages-name">{{ pageData.mainContent?.advantages_fourth_title }}</div>
            <div class="about-info__advantages-text">{{ pageData.mainContent?.advantages_fourth_text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-services">
      <div class="container">
        <div class="about-services__wrapper">
          <div class="about-services__item">
            <div class="about-services__title"><img src="/images/checked.svg" alt="Lowest prices">{{ pageData.mainContent?.services_first_title }}</div>
            <div class="about-services__text">{{ pageData.mainContent?.services_first_text }}</div>
          </div>
          <div class="about-services__item">
            <div class="about-services__title"><img src="/images/checked.svg" alt="No bill booking">{{ pageData.mainContent?.services_second_title }}</div>
            <div class="about-services__text">{{ pageData.mainContent?.services_second_text }}</div>
          </div>
          <div class="about-services__item">
            <div class="about-services__title"><img src="/images/checked.svg" alt="Security">{{ pageData.mainContent?.services_third_title }}</div>
            <div class="about-services__text">{{ pageData.mainContent?.services_third_text }}</div>
          </div>
          <div class="about-services__item">
            <div class="about-services__title"><img src="/images/checked.svg" alt="Support">{{ pageData.mainContent?.services_fourth_title }}</div>
            <div class="about-services__text">{{ pageData.mainContent?.services_fourth_text }}</div>
          </div>
          <div class="about-services__item">
            <div class="about-services__title"><img src="/images/checked.svg" alt="Payments">{{ pageData.mainContent?.services_fifth_title }}</div>
            <div class="about-services__text">{{ pageData.mainContent?.services_fifth_text }}</div>
          </div>
          <div class="about-services__item">
            <div class="about-services__title"><img src="/images/checked.svg" alt="Warranty">{{ pageData.mainContent?.services_sixth_title }}</div>
            <div class="about-services__text">{{ pageData.mainContent?.services_sixth_text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingIndicator from "@/components/globals/LoadingIndicator.vue";

export default {
  name: 'AboutView',
  components: {LoadingIndicator},
  data() {
    return {
      pageData: {
        mainContent: null
      }
    };
  },
  computed: {
    ...mapGetters(['getCurrentLanguage']),
  },
  methods:{
    async fetchAboutPage(){
      const locale = this.$i18n.locale;
      const URI_API = process.env.VUE_APP_API_URL;
      const url = `${URI_API}/page/${locale}/about`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        this.pageData = await response.json();
      } catch (error) {
        console.error(error);
      }
    }
  },
  async created() {
    await this.fetchAboutPage();
  },
  watch: {
    getCurrentLanguage(newLang, oldLang) {
      this.fetchAboutPage();
    }
  },
};
</script>
