import axios from 'axios';
import { openDB } from 'idb';
import { capitalizeEachWord } from "@/utilities/service";

const URI_API = process.env.VUE_APP_API_URL;
const DB_NAME = 'cacheDB';
const STORE_NAME = 'yachtTypes';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

const state = {
    yachtTypes: []
};

const mutations = {
    SET_YACHT_TYPES(state, yachtTypes) {
        state.yachtTypes = yachtTypes;
    }
};

const actions = {
    async fetchYachtTypes({ commit }) {
        try {
            const db = await openDB(DB_NAME, 1, {
                upgrade(db) {
                    db.createObjectStore(STORE_NAME, { keyPath: 'id' });
                }
            });

            const cachedData = await db.get(STORE_NAME, 'yachtTypes');
            const now = new Date().getTime();

            if (cachedData && now < cachedData.expiry) {
                commit('SET_YACHT_TYPES', cachedData.data);
                return;
            }

            // If no valid cache, fetch from API
            const url = `${URI_API}/yachts-types`;
            const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenBearer}`
                }
            });

            const data = response.data;
            const normalizedYachtTypes = data.data.map((yachtType) => {
                return {
                    ...yachtType,
                    text: capitalizeEachWord(yachtType.name)
                };
            });

            // Commit to Vuex state
            commit('SET_YACHT_TYPES', normalizedYachtTypes);

            // Cache the data with a timestamp for expiry
            await db.put(STORE_NAME, {
                id: 'yachtTypes',
                data: normalizedYachtTypes,
                expiry: now + CACHE_DURATION
            });

        } catch (error) {
            console.error('Error fetching yachtTypes:', error);
        }
    }
};

const getters = {
    getYachtTypes: state => state.yachtTypes
};

export default {
    state,
    mutations,
    actions,
    getters
};
